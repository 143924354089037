// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { TopicIndexViewModel } from './topicindexview-model';
import { SchoolClassIndexViewModel } from './schoolclassindexview-model';

import { CourseType } from './enums';

export class CourseIndexViewModel {
    id: number = 0;
	name: string = null;
	showInQuickEntry: boolean = false;
	courseType: CourseType = null;
	topics: TopicIndexViewModel[] = [];
	schoolClass: SchoolClassIndexViewModel = null;
	totalImportance: number = 0;
	totalScored: number = 0;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('name').displayName('Naam').required()
			.on(this);
	}
}