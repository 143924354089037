import { inject, autoinject, LogManager, bindable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';
import { Endpoint, Rest } from 'aurelia-api';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { Helpers } from '../../services/helpers';
import { CheckType } from '../../models/enums';
import { AssignmentViewModel } from '../../models/assignmentview-model';
import { AssignmentEditViewModel } from '../../models/assignmenteditview-model';
import { TopicViewModel } from '../../models/topicview-model';

@autoinject
export class AssignmentsModalUpdate {
    @bindable id: number = 0;
    model: AssignmentEditViewModel = null;
    private logger: Logger = LogManager.getLogger('ASSIGNMENTMODALUPDATE');
    private validationController: ValidationController | null = null;
    private saveDisabled: boolean = false;

    private checkTypeKeys: string[];
    private checkTypeValues: number[];
    private checkTypeId: number = null; 

    private topics: TopicViewModel[] = [];

    constructor(@inject(Endpoint.of('api')) private api: Rest,
        private validationControllerFactory: ValidationControllerFactory,
        private dialogController: DialogController) {
        this.validationController = validationControllerFactory.createForCurrentScope();

        this.validationController.addRenderer(new BootstrapFormRenderer());
    }

    async activate(params: any) {
        this.logger.debug('activate');
        this.id = params.assignmentId;

        this.getEnumValues();
        await this.getModel();
        await this.getTopics();
    }

    async getTopics() {
        this.logger.debug('getTopics');

        await this.api.find('/Topic/GetAll_Sorted')
			.then((data: Array<TopicViewModel>) => {
                this.topics = data;
                this.logger.debug('Success getTopics: ', data);
			}).catch(async (err) => {
                this.logger.debug('Error getTopics: ', await err.json());
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

    getEnumValues() {
		this.checkTypeKeys = ["Aanmaken", "Verwijderen", "Hernoemen", "Financiële jaar aanmaken"];
		this.checkTypeValues = [0, 1, 2, 3];
    }

    async getModel() {
        this.logger.debug('getModel');

        await this.api.find('/Assignment/GetById', this.id)
            .then(async (data: AssignmentViewModel) => {
                this.logger.debug('Success getModel: ', data);
                this.model = new AssignmentViewModel(data);
                this.checkTypeId = data.checkType; // set enum
            }).catch(async (err) => {
                this.logger.debug('Error getModel: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }


    async save() {
        this.logger.debug('save');
        this.saveDisabled = true;

        this.model.checkType = this.checkTypeId; // set enum

        const validation: ControllerValidateResult = await this.validationController.validate();

        if (validation) {
            await this.api.update('/Assignment/Update', this.id, this.model)
                .then((data: AssignmentEditViewModel) => {
                    this.logger.debug('Success save: ' + data);
                    this.saveDisabled = false;
                    this.dialogController.ok(data);
                }).catch(async (err) => {
                    this.saveDisabled = false;
                    this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                });
        }
    }

    cancel() {
        this.dialogController.cancel();
    }
}