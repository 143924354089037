import { valueConverter } from 'aurelia-framework';

/**
 * Sorts values by property and direction
 */

@valueConverter('boolToYesNo')
export class BoolToYesNoValueConverter {
	toView(value: boolean) {
		if (value) {
			return "Ja";
		} else {
			return "Nee";
		}
	}
}
