import { Helpers } from "../services/helpers";

export class ValidationResult {
	errorMessage: string | null = null;
	memberNames: Array<string> | null = null;

	constructor(data: any | {} = {}) {
		// maps only the keys within this class.
		Helpers.mapProperties(data, this);
	}
}

export class ErrorResponseModel {
	property: string | null = null;
	statusCode: number | null = null;
	errorMessage: string | null = null;

	constructor(data: any | {} = {}) {
		// maps only the keys within this class.
		Helpers.mapProperties(data, this);
	}
}

export class HttpError extends Error {
	msg: string | null = null;
	errorList: Array<ErrorResponseModel> | null = null;

	constructor(msg: any, errorList: Array<ErrorResponseModel>) {
		super();
		this.msg = msg;
		this.errorList = errorList;
	}
}