import { autoinject, bindable, customElement } from 'aurelia-framework';
import { Router } from 'aurelia-router';

@autoinject
@customElement('history-back')
export default class {
	@bindable text: string | null = null;
	@bindable altUrl: string | null = null;

    constructor(private router: Router) {
    }

    public async attached() {
    }

	public async goBack() {
		if (this.altUrl) {
			await this.router.navigate(this.altUrl);
		}
		else {
			window.history.back();
		}
    }
}