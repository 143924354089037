// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { SchoolViewModel } from './schoolview-model';
import { SchoolYearViewModel } from './schoolyearview-model';
import { SchoolClassViewModel } from './schoolclassview-model';


export class UserViewModel {
    id: string = null;
	userName: string = null;
	email: string = null;
	division: string = null;
	phoneNumber: string = null;
	schoolId: number = null;
	schoolYearId: number = null;
	schoolClassId: number = null;
	school: SchoolViewModel = null;
	schoolYear: SchoolYearViewModel = null;
	schoolClass: SchoolClassViewModel = null;
	isActive: boolean = true;
	lastLoginDate: Date = null;
	firstLoginDate: Date = null;
	fullName: string = null;
	setupCompleted: boolean = false;
	firstFetchCompleted: boolean = false;
	schoolName: string = null;
	schoolClassName: string = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('userName').displayName('Gebruikersnaam').required()
			.ensure('email').displayName('E-mail').required()
			.on(this);
	}
}