import { inject, autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { Rest, Endpoint } from 'aurelia-api';
import { ValidationControllerFactory, ValidationController, ControllerValidateResult } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { Router } from 'aurelia-router';
import { ForgotPasswordViewModel } from '../../models/forgotpasswordview-model';
import { Helpers } from '../../services/helpers';

@autoinject
export class ForgotPassword {

	private logger: Logger = LogManager.getLogger('FORGOTPASSWORD');
	private validationController: ValidationController | null = null;
	public model: ForgotPasswordViewModel;

	constructor(
		@inject(Endpoint.of('api'))
		private api: Rest,
		private validationControllerFactory: ValidationControllerFactory,
		private router: Router) {

		this.validationController = validationControllerFactory.createForCurrentScope();
		this.validationController.addRenderer(new BootstrapFormRenderer());
	}

	async activate() {
		this.logger.debug('activate')
		this.model = new ForgotPasswordViewModel();
	}

	async forgotpass() {
		this.logger.debug('forgotpass');
		const validation: ControllerValidateResult = await this.validationController.validate();

		if (validation.valid) {
			await this.api.create('/Account/SendForgottenPasswordEmail', this.model)
				.then((data) => {
					this.logger.debug('Success forgotpassword: ', data);
				}).catch(async (err) => {
					this.logger.debug('Error forgotpassword: ', err);
					await Helpers.ParseValidationError(err, this.validationController, this.model);
				});
		}

		// Always redirect to confirmation page, also when errors
		this.router.navigate("forgot-password-confirmation");
	}
}
