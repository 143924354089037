// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { AssignmentViewModel } from './assignmentview-model';


export class SubAssignmentEditViewModel {
    id: number = 0;
	order: number = 0;
	description: string = null;
	assignmentId: number = 0;
	assignment: AssignmentViewModel = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('order').displayName('Volgorde').required()
			.ensure('description').displayName('Omschrijving').required()
			.ensure('assignmentId').displayName('Opdracht').required()
			.on(this);
	}
}