import { inject, autoinject, LogManager, bindable } from 'aurelia-framework';
import { DialogController, DialogService } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';
import { Endpoint, Rest } from 'aurelia-api';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { Helpers } from '../../services/helpers';
import { AnswerViewModel } from '../../models/answerview-model';
import { AnswerEditViewModel } from '../../models/answereditview-model';
import { SubAssignmentViewModel } from '../../models/subassignmentview-model';
import { ApiToolModal } from '../apitool/apitool-modal';

@autoinject
export class AnswersModalUpdate {
    @bindable id: number = 0;
    model: AnswerViewModel = null;
    private logger: Logger = LogManager.getLogger('ANSWERMODALUPDATE');
    private validationController: ValidationController | null = null;
    private saveDisabled: boolean = false;
    private subAssignments: SubAssignmentViewModel[] = [];

    constructor(@inject(Endpoint.of('api')) private api: Rest,
        private validationControllerFactory: ValidationControllerFactory,
		private dialogController: DialogController,
		private dialogService: DialogService) {
        this.validationController = validationControllerFactory.createForCurrentScope();

        this.validationController.addRenderer(new BootstrapFormRenderer());
    }

    async activate(params: any) {
        this.logger.debug('activate');
        this.id = params.answerId;

        await this.getModel();
        await this.getSubAssignments();
    }

    async getModel() {
        this.logger.debug('getModel');

        await this.api.find('/Answer/GetById', this.id)
            .then(async (data: AnswerViewModel) => {
                this.logger.debug('Success getModel: ', data);
                this.model = new AnswerViewModel(data);
            }).catch(async (err) => {
                this.logger.debug('Error getModel: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

    async getSubAssignments() {
        this.logger.debug('getSubAssignments');

        await this.api.find('/SubAssignment/GetAll_Sorted')
            .then((data: Array<SubAssignmentViewModel>) => {
                this.subAssignments = data;
                this.logger.debug('Success getSubAssignments: ', data);
            }).catch(async (err) => {
                this.logger.debug('Error getSubAssignments: ', await err.json());
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
	}

	async showAPITool() {
		this.logger.debug('show API tool!');
		this.dialogService.open({ viewModel: ApiToolModal, model: { answerModel: this.model } });
	}

    async save() {
        this.logger.debug('save');
        this.saveDisabled = true;

        const validation: ControllerValidateResult = await this.validationController.validate();

        if (validation) {
            await this.api.update('/Answer/Update', this.id, this.model)
                .then((data: AnswerEditViewModel) => {
                    this.logger.debug('Success save: ' + data);
                    this.saveDisabled = false;
                    this.dialogController.ok(data);
                }).catch(async (err) => {
                    this.saveDisabled = false;
                    this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                });
        }
    }

    cancel() {
        this.dialogController.cancel();
    }
}