import { valueConverter } from 'aurelia-framework';
import * as numeral from 'numeral';

@valueConverter('price')
export class PriceValueConverter {
    public toView(value: string, format: string = '0,0.00', locale: string = 'nl') {
        return numeral(value).format(format);
    }

    //public fromView(value: string): number {
    //    return numeral(value).value();
    //}
}

@valueConverter('distancekm')
export class DistanceValueConverter {
	public toView(value: string, format: string = '0', locale: string = 'nl') {
		return numeral(value).format(format);
	}

	//public fromView(value: string): number {
	//    return numeral(value).value();
	//}
}