// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { ApplicationUserViewModel } from './applicationuserview-model';
import { AnswerViewModel } from './answerview-model';
import { CourseViewModel } from './courseview-model';


export class EOLStoredDataViewModel {
    userId: string = null;
	answerId: number = 0;
	courseId: number = 0;
	division: string = null;
	fetchedAnswer: string = null;
	user: ApplicationUserViewModel = null;
	answer: AnswerViewModel = null;
	course: CourseViewModel = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('userId').displayName('userId').required()
			.ensure('answerId').displayName('answerId').required()
			.ensure('courseId').displayName('courseId').required()
			.on(this);
	}
}