import { autoinject, inject, bindable, LogManager } from 'aurelia-framework';
import { Endpoint, Rest } from 'aurelia-api';
import { Logger } from 'aurelia-logging';
import { DialogController } from 'aurelia-dialog';
import { ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { ServiceEndpointViewModel } from '../../models/serviceendpointview-model';
import { ServiceEndpointCreateViewModel } from '../../models/serviceendpointcreateview-model';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { Helpers } from '../../services/helpers';
import { observable } from 'aurelia-binding';
import { HttpError } from '../../models/general';
import { AnswerViewModel } from '../../models/answerview-model';
import { AnswerSelectedFilterViewModel } from '../../models/answerselectedfilterview-model';
import { AnswerSelectedFieldViewModel } from '../../models/answerselectedfieldview-model';
import { UserViewModel } from '../../models/userview-model';

@autoinject
export class ApiToolModal {
	private logger: Logger = LogManager.getLogger('APITOOLMODAL');
	private validationController: ValidationController | null = null;
	private serviceEndpoints: ServiceEndpointViewModel[] = [];
	private saveDisabled: boolean = false;

	private apiCommand: string = "";
	@observable filter: string = "";
	@observable uri: string = "";

	private model: ServiceEndpointCreateViewModel = new ServiceEndpointCreateViewModel();
	@observable private selectedEndpoint: ServiceEndpointViewModel = new ServiceEndpointViewModel();

	@observable private selectedFields: string[] = [];
	private selectedFieldsString: string;
	private selectedFilters: AnswerSelectedFilterViewModel[] = [];

	private fieldsdata: string[];
	private filterResults: string[];
	private answerModel: AnswerViewModel;
	private userModel: UserViewModel;

	constructor(
		@inject(Endpoint.of('api')) private api: Rest,
		private validationControllerFactory: ValidationControllerFactory,
		private dialogController: DialogController,
		private dialog: DialogController) {

		this.validationController = validationControllerFactory.createForCurrentScope();
		this.validationController.addRenderer(new BootstrapFormRenderer());
	}

	async activate(params: any) {
		this.logger.debug('activate');

		if (params && params.answerModel) {
			this.answerModel = params.answerModel;
		}

		await this.getServiceEndpoints()
			.then(() => {
				if (params && params.answerModel) {
					this.selectedEndpoint = this.serviceEndpoints.find(x => x.uri == this.answerModel.uri);
				}
			});

		//set apiCommand last
		if (params && params.answerModel) {
			this.apiCommand = params.answerModel.apiCommand;
		}

		if (params && params.answerModel) {
			await this.getSelectedFields();
			await this.getSelectedFilters();
		}

		await this.api.find('/User/GetCurrentUser').then(async (data: UserViewModel) => {
			this.userModel = new UserViewModel(data);
		});
	}

	async getSelectedFields() {
		if (this.answerModel.answerSelectedFields != null && this.answerModel.answerSelectedFields.length > 0) {
			this.selectedFieldsString = this.answerModel.answerSelectedFields.map(e => e.selectedField).join();

			var selFields = [];
			this.answerModel.answerSelectedFields.forEach(function (answer) {
				selFields.push(answer.selectedField);
			});
			this.selectedFields = selFields;
		};
	}

	async getSelectedFilters() {
		if (this.answerModel.answerSelectedFilters != null && this.answerModel.answerSelectedFilters.length > 0) {			
			for (var i = 0; i < this.answerModel.answerSelectedFilters.length; i++) {
				var filterModel = new AnswerSelectedFilterViewModel();
				filterModel.answerId = this.answerModel.id;
				filterModel.filterFieldName = this.answerModel.answerSelectedFilters[i].filterFieldName;
				filterModel.filterFieldOperator = this.answerModel.answerSelectedFilters[i].filterFieldOperator;
				filterModel.filterFieldValue = this.answerModel.answerSelectedFilters[i].filterFieldValue;
				this.selectedFilters.push(filterModel);
			}		
		}
	}

	async getServiceEndpoints() {
		this.logger.debug('getServiceEndpoints');

		await this.api.find('/ServiceEndpoint/GetAll')
			.then((data: Array<ServiceEndpointViewModel>) => {
				this.serviceEndpoints = data;
				this.logger.debug('Success getServiceEndpoints: ', data);
			}).catch(async (err) => {
				this.logger.debug('Error getServiceEndpoints: ', await err.json());
				await Helpers.ParseValidationError(err, this.validationController, this.model);
			});
	}

	async cancel() {
		this.logger.debug('cancel');
		await this.dialogController.cancel();
	}

	async addFilterLine() {
		this.logger.debug('addFilterLine');
		this.selectedFilters.push(new AnswerSelectedFilterViewModel());
	}

	async save() {
		this.logger.debug('save');

		// add fields
		this.answerModel.answerSelectedFields = [];
		var fields = this.selectedFieldsString.split(',');
		for (var i = 0; i < fields.length; i++) {
			var fieldModel = new AnswerSelectedFieldViewModel();
			fieldModel.answerId = this.answerModel.id;
			fieldModel.selectedField = fields[i];
			this.answerModel.answerSelectedFields.push(fieldModel);
		}

		// add filters
		this.answerModel.answerSelectedFilters = [];
		for (var i = 0; i < this.selectedFilters.length; i++) {
			// only add when there's an actual filter
			if (this.selectedFilters[i].filterFieldName && this.selectedFilters[i].filterFieldValue) {
				var filterModel = new AnswerSelectedFilterViewModel();
				filterModel.answerId = this.answerModel.id;
				filterModel.filterFieldName = this.selectedFilters[i].filterFieldName;
				filterModel.filterFieldOperator = this.selectedFilters[i].filterFieldOperator;
				filterModel.filterFieldValue = this.selectedFilters[i].filterFieldValue;
				this.answerModel.answerSelectedFilters.push(filterModel);
			}
		}

		this.answerModel.uri = this.uri;
		this.answerModel.entity = this.selectedEndpoint.entity;
		this.answerModel.isXMLCall = this.selectedEndpoint.isXMLCall;

		this.dialogController.ok(this.answerModel);
	}

	async selectedEndpointChanged(newValue, oldValue) {
		if (newValue != oldValue && newValue != null) {
			if (this.selectedEndpoint.uri != null) {
				this.uri = this.selectedEndpoint.uri;
				await this.getFieldsForEndpoint();

				// reset if we changed a value
				if (oldValue && oldValue.uri != null) {
					this.selectedFields = [];
					this.selectedFieldsString = "";
					this.selectedFilters = [];
					this.filter = "";
				}
			}
		}
	}

	private async selectedFieldsChanged() {
		this.selectedFieldsString = this.selectedFields.join(",");
	}

	async getFieldsForEndpoint() {
		this.logger.debug('getFieldsForEndpoint');
		this.saveDisabled = true;

		if (this.selectedEndpoint) {
			await this.api.find(`/ExactOnline/GetFieldsForEndpoint?entity=${this.selectedEndpoint.entity}&isSettings=${this.selectedEndpoint.isXMLCall}`)
				.then((data: Array<string>) => {
					this.fieldsdata = data;
					this.logger.debug('Success: getModel');
				}).catch(async (err) => {
					var errorObj = await err.json();
					this.logger.debug('Error getModel: ', errorObj);
				});
		}

		this.saveDisabled = false;
	}

	async filterResult() {
		var answerFields = [];
		var answerFilters = [];

		var selFields = this.selectedFieldsString.split(',');
		for (var i = 0; i < selFields.length; i++) {
			var fieldModel = new AnswerSelectedFieldViewModel();
			fieldModel.answerId = this.answerModel.id;
			fieldModel.selectedField = selFields[i];
			answerFields.push(fieldModel);
		}

		for (var i = 0; i < this.selectedFilters.length; i++) {
			// only add when there's an actual filter
			if (this.selectedFilters[i].filterFieldName && this.selectedFilters[i].filterFieldValue) {
				var filterModel = new AnswerSelectedFilterViewModel();
				filterModel.answerId = this.answerModel.id;
				filterModel.filterFieldName = this.selectedFilters[i].filterFieldName;
				filterModel.filterFieldOperator = this.selectedFilters[i].filterFieldOperator;
				filterModel.filterFieldValue = this.selectedFilters[i].filterFieldValue;
				answerFilters.push(filterModel);
			}
		}

		var entity = this.selectedEndpoint.entity;
		var fields = JSON.stringify(answerFields);
		var filters = JSON.stringify(answerFilters);
		var user = JSON.stringify(this.userModel);

		let passUri: string = encodeURIComponent(this.uri);

		if (this.selectedEndpoint.isXMLCall) {
			// use credentials to connect to Exact Online and call
			await this.api.find(`/ExactOnline/FilterNewXMLResult?user=${user}&entity=${entity}&fields=${fields}&filters=${filters}`)
				.then((data: string[]) => {
					// how to define a c#-tuple?
					this.filterResults = data;
					this.logger.debug('Success filterResult');
				}).catch(async (err) => {
					var errorObj = await err.json();
					this.logger.debug('Error filterResult', errorObj);
				});
		} else {
			await this.api.find(`/ExactOnline/FilterNewResult?user=${user}&entity=${entity}&fields=${fields}&filters=${filters}`)
				.then((data: string[]) => {
					// how to define a c#-tuple?
					this.filterResults = data;
					this.logger.debug('Success filterResult');
				}).catch(async (err) => {
					var errorObj = await err.json();
					this.logger.debug('Error filterResult', errorObj);
				});
		}

		this.logger.debug('filterResult');
	}
}