export enum CheckType {
    ShouldBeCreated = 0,
    ShouldBeRemoved = 1,
    ShouldBeRenamed = 2,
    FinYearShouldBeCreated = 3
}

export enum CourseType {
    Course = 0,
    Exam = 1
}

export enum TopicState {
    Open = 0,
    Closed = 1,
    ReadOnly = 2
}

export enum EmailTypes {
    ForgottenPassword = 0
}

