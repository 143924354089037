import { Rest, Endpoint } from 'aurelia-api';
import { autoinject, inject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { DialogController } from 'aurelia-dialog';
import { Helpers } from '../../services/helpers';
import { AssignmentCreateViewModel } from './../../models/assignmentcreateview-model'
import { CheckType	} from '../../models/enums';
import { TopicViewModel } from '../../models/topicview-model';

@autoinject
export class AssignmentsModalCreate {
	private validationController: ValidationController | null = null;
	private logger: Logger = LogManager.getLogger('ASSIGNMENTMODALCREATE');
    public model: AssignmentCreateViewModel = null;

    private checkTypeKeys: string[];
    private checkTypeValues: number[];
    private checkTypeId: number = null; 

    private topics: TopicViewModel[] = [];

	private saveDisabled: boolean = false;
	public topicId: number | null = null;

	constructor(
		@inject(Endpoint.of('api'))
		private api: Rest,
		private validationControllerFactory: ValidationControllerFactory,
		private dialogController: DialogController) {
			this.validationController = validationControllerFactory.createForCurrentScope();
			this.validationController.addRenderer(new BootstrapFormRenderer());
	}

    async activate(params: any) {
        this.logger.debug("activate");
        this.getEnumValues();
        this.model = new AssignmentCreateViewModel();

		//optional
		if (params && params.topicId) {
			this.topicId = params.topicId;
		}

		await this.getOrderNumber();
        await this.getTopics();
    }

	async getOrderNumber() {
		this.logger.debug('getOrderNumber');

		if (this.topicId) {
			await this.api.find('/Assignment/GetMaxOrder', this.topicId)
				.then((data: number) => {
					this.model.order = data;
					this.logger.debug('Success getOrderNumber: ', data);
				}).catch(async (err) => {
					this.logger.debug('Error getOrderNumber: ', await err.json());
					await Helpers.ParseValidationError(err, this.validationController, this.model);
				});
		} else {
			this.model.order = 1;
		}
	}

    async getTopics() {
        this.logger.debug('getTopics');

        await this.api.find('/Topic/GetAll_Sorted')
            .then((data: Array<TopicViewModel>) => {
				this.topics = data;

				// set the topicId if it's provided
				if (this.topicId) {
					this.model.topicId = this.topicId;
				}

                this.logger.debug('Success getTopics: ', data);
            }).catch(async (err) => {
                this.logger.debug('Error getTopics: ', await err.json());
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

	getEnumValues() {
		this.checkTypeKeys = ["Aanmaken", "Verwijderen", "Hernoemen", "Financiële jaar aanmaken"];
		this.checkTypeValues = [0, 1, 2, 3];
    }

    async save() {
		this.logger.debug('save');
        this.saveDisabled = true;

        this.model.checkType = this.checkTypeId; // set enum

		const validation: ControllerValidateResult = await this.validationController.validate();

        if (validation.valid !== false) {
			await this.api.create('/Assignment/Insert', this.model)
                .then((data: AssignmentCreateViewModel) => {
					this.saveDisabled = false;
                    this.logger.debug('Success save: ', data);
					this.dialogController.ok(data);
                }).catch(async (err) => {
                    this.saveDisabled = false;
                    this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                });
		} else {
			this.saveDisabled = false;
		}
	}

	async cancel() {
		await this.dialogController.cancel();
	}
}