import { Rest, Endpoint } from 'aurelia-api';
import { autoinject, inject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { DialogController } from 'aurelia-dialog';
import { DialogService } from 'aurelia-dialog';
import { Helpers } from '../../services/helpers';
import { AnswerCreateViewModel } from '../../models/answercreateview-model'
import { SubAssignmentViewModel } from '../../models/subassignmentview-model';
import { ApiToolModal } from '../apitool/apitool-modal';
import { ServiceEndpointCreateViewModel } from '../../models/serviceendpointcreateview-model';

@autoinject
export class AnswersModalCreate {
	private validationController: ValidationController | null = null;
	private logger: Logger = LogManager.getLogger('ANSWERMODALCREATE');
    public model: AnswerCreateViewModel = null;
    private subAssignments: SubAssignmentViewModel[] = [];
	public subAssignmentId: number | null = null;
	private saveDisabled: boolean = false;

	constructor(
		@inject(Endpoint.of('api'))
		private api: Rest,
		private validationControllerFactory: ValidationControllerFactory,
		private dialogController: DialogController,
		private dialogService: DialogService) {
			this.validationController = validationControllerFactory.createForCurrentScope();
			this.validationController.addRenderer(new BootstrapFormRenderer());
	}

    async activate(params: any) {
        this.logger.debug("activate");
        this.model = new AnswerCreateViewModel();

		//optional
		if (params && params.subAssignmentId) {
			this.subAssignmentId = params.subAssignmentId;
		}

		await this.getOrderNumber();
        await this.getSubAssignments();
    }

	async getOrderNumber() {
		this.logger.debug('getOrderNumber');

		if (this.subAssignmentId) {
			await this.api.find('/Answer/GetMaxOrder', this.subAssignmentId)
				.then((data: number) => {
					this.model.order = data;
					this.logger.debug('Success getOrderNumber: ', data);
				}).catch(async (err) => {
					this.logger.debug('Error getOrderNumber: ', await err.json());
					await Helpers.ParseValidationError(err, this.validationController, this.model);
				});
		} else {
			this.model.order = 1;
		}
	}

    async getSubAssignments() {
        this.logger.debug('getSubAssignments');

        await this.api.find('/SubAssignment/GetAll_Sorted')
            .then((data: Array<SubAssignmentViewModel>) => {
				this.subAssignments = data;

				// set the subAssignmentId if it's provided
				if (this.subAssignmentId) {
					this.model.subAssignmentId = this.subAssignmentId;
				}

                this.logger.debug('Success getSubAssignments: ', data);
            }).catch(async (err) => {
                this.logger.debug('Error getSubAssignments: ', await err.json());
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

    async save() {
		this.logger.debug('save');
        this.saveDisabled = true;

		const validation: ControllerValidateResult = await this.validationController.validate();

        if (validation.valid !== false) {
			await this.api.create('/Answer/Insert', this.model)
				.then((data: AnswerCreateViewModel) => {
					this.saveDisabled = false;
                    this.logger.debug('Success save: ', data);
					this.dialogController.ok(data);
				}).catch(async (err) => {
                    this.saveDisabled = false;
                    this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                });
		} else {
			this.saveDisabled = false;
		}
	}

	async showAPITool() {
		this.logger.debug('show API tool!');
		this.dialogService.open({ viewModel: ApiToolModal, model: { answerModel: this.model } });
	}

	async cancel() {
		await this.dialogController.cancel();
	}
}