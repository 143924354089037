import { inject } from 'aurelia-dependency-injection';
import { Redirect } from 'aurelia-router';
import { AuthService } from "aurelia-authentication";
import { SharedState } from '../state/shared-state';

// https://stackoverflow.com/a/42631737
@inject(AuthService, SharedState)
export class SaveNavAuthenticateStep {
    authService: AuthService;
    commonState: SharedState;

    constructor(authService: AuthService, commonState: SharedState) {
        this.authService = authService;
        this.commonState = commonState;
    }

    run(routingContext, next) {
        const isLoggedIn = this.authService.authenticated;
        const loginRoute = this.authService.config.loginRoute;

        if (routingContext.getAllInstructions().some(route => route.config.auth === true)) {
            if (!isLoggedIn) {
                this.commonState.postLoginNavInstr = routingContext;

                return next.cancel(new Redirect(loginRoute));
            }
        } else if (isLoggedIn && routingContext.getAllInstructions().some(route => route.fragment === loginRoute)) {
            return next.cancel(new Redirect(this.authService.config.loginRedirect));
        }

        return next();
    }
}