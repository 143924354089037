import { Endpoint, Rest } from 'aurelia-api';
import { DialogService } from 'aurelia-dialog';
import { autoinject, inject, LogManager } from "aurelia-framework";
import { Logger } from 'aurelia-logging';
import { HttpError } from "../../models/general";
import { SchoolIndexViewModel } from "../../models/schoolindexview-model";
import { Helpers } from "../../services/helpers";
import { SchoolsModalCreate } from "./../schools/schools-modal-create";
import { SchoolsModalDelete } from "./../schools/schools-modal-delete";
import { SchoolsModalUpdate } from "./../schools/schools-modal-update";
import { SchoolYearsModalCreate } from "./../schoolyears/schoolyears-modal-create";
import { SchoolYearsModalDelete } from "./../schoolyears/schoolyears-modal-delete";
import { SchoolYearsModalUpdate } from "./../schoolyears/schoolyears-modal-update";
import { SchoolClassModalCreate } from '../schoolclasses/schoolclasses-modal-create';
import { SchoolClassModalUpdate } from '../schoolclasses/schoolclasses-modal-update';
import { SchoolClassModalDelete } from '../schoolclasses/schoolclasses-modal-delete';
import { StudentModalCreate } from '../students/student-modal-create';
import { StudentModalUpdate } from '../students/student-modal-update';
import { StudentModalDelete } from '../students/student-modal-delete';
import { SchoolClassViewModel } from '../../models/schoolclassview-model';
import { SchoolYearViewModel } from '../../models/schoolyearview-model';
import { SchoolViewModel } from '../../models/schoolview-model';

@autoinject
export class SchoolsTree {
	private logger: Logger = LogManager.getLogger('SCHOOLSTREE');
	private isRequesting: boolean = true;
	private serverError: HttpError | null = null;
	private model: Array<SchoolIndexViewModel> | null = null;
	private search: string | null = null;
	private selectedField: string = 'name';
	private ascending: boolean = true;

	constructor(
		@inject(Endpoint.of('api'))
		private api: Rest,
		private dialogService: DialogService) { }

	async bind() {
		await this.getModel();
	}

	async getModel() {
		this.logger.debug('getModel');
        this.isRequesting = true;

		await this.api.find('/School/GetAllInclusive')
			.then((data: Array<SchoolIndexViewModel>) => {
                this.model = data;
				Helpers.sortModel(this.model, this.selectedField, this.ascending);
                this.logger.debug('Success getModel');
            }).catch(async (error) => {
                this.logger.debug('Error getModel: ', await error.json());
            });

        this.isRequesting = false;
	}

	sortModel(_class: Object[], field: string) {
		this.selectedField = field;
		this.ascending === false ? this.ascending = true : this.ascending = false;
		Helpers.sortModel(_class, this.selectedField, this.ascending);
	}

	async newSchool() {
		this.logger.debug('newSchool');
		this.dialogService.open({ viewModel: SchoolsModalCreate }).whenClosed(response => this.onClose(response));
	}

	async editSchool(id) {
		this.logger.debug('editSchool');
		this.dialogService.open({ viewModel: SchoolsModalUpdate, model: { schoolId: id }}).whenClosed(response => this.onClose(response));
	}

	async copySchool(id) {
		this.logger.debug('copySchool');
		this.isRequesting = true;

		await this.api.find('/School/Copy', id)
			.then(async (data: SchoolViewModel) => {
				await this.getModel();
				this.logger.debug('Success copySchool');
			}).catch(async (error) => {
				this.logger.debug('Error copySchool: ', await error.json());
			});

		this.isRequesting = false;
	}

	async deleteSchool(id) {
		this.logger.debug('deleteSchool');
		this.dialogService.open({ viewModel: SchoolsModalDelete, model: { schoolId: id }}).whenClosed(response => this.onClose(response));
	}

    async newYear(schoolId) {
        this.logger.debug('newYear');
		this.dialogService.open({ viewModel: SchoolYearsModalCreate, model: { schoolId: schoolId }}).whenClosed(response => this.onClose(response));
    }

    async editYear(id) {
        this.logger.debug('editYear');
		this.dialogService.open({ viewModel: SchoolYearsModalUpdate, model: { yearId: id }}).whenClosed(response => this.onClose(response));
    }

	async copyYear(id) {
		this.logger.debug('copyYear');
		this.isRequesting = true;

		await this.api.find('/SchoolYear/Copy', id)
			.then(async (data: SchoolYearViewModel) => {
				await this.getModel();
				this.logger.debug('Success copyYear');
			}).catch(async (error) => {
				this.logger.debug('Error copyYear: ', await error.json());
			});

		this.isRequesting = false;
	}

    async deleteYear(id) {
        this.logger.debug('deleteYear');
		this.dialogService.open({ viewModel: SchoolYearsModalDelete, model: { yearId: id }}).whenClosed(response => this.onClose(response));
    }

    async newClass(yearId) {
        this.logger.debug('newClass');
		this.dialogService.open({ viewModel: SchoolClassModalCreate, model: { yearId: yearId }}).whenClosed(response => this.onClose(response));
    }

    async editClass(id) {
        this.logger.debug('editClass');
		this.dialogService.open({ viewModel: SchoolClassModalUpdate, model: { classId: id }}).whenClosed(response => this.onClose(response));
    }

	async copyClass(id) {
		this.logger.debug('copyClass');
		this.isRequesting = true;

		await this.api.find('/SchoolClass/Copy', id)
			.then(async (data: SchoolClassViewModel) => {
				await this.getModel();
				this.logger.debug('Success copyClass');
			}).catch(async (error) => {
				this.logger.debug('Error copyClass: ', await error.json());
			});

		this.isRequesting = false;
	}

    async deleteClass(id) {
        this.logger.debug('deleteClass');
		this.dialogService.open({ viewModel: SchoolClassModalDelete, model: { classId: id }}).whenClosed(response => this.onClose(response));
    }

    async newStudent(classId) {
        this.logger.debug('newStudent');
		this.dialogService.open({ viewModel: StudentModalCreate, model: { classId: classId }}).whenClosed(response => this.onClose(response));
    }

    async editStudent(id) {
        this.logger.debug('editStudent');
		this.dialogService.open({ viewModel: StudentModalUpdate, model: { studentId: id } }).whenClosed(response => this.onClose(response));
    }

    async deleteStudent(id) {
        this.logger.debug('deleteStudent');
		this.dialogService.open({ viewModel: StudentModalDelete, model: { studentId: id } }).whenClosed(response => this.onClose(response));
    }

	async onClose(response) {
		if (!response.wasCancelled) {
			await this.getModel();
			return response.output;
		} else {
			return null;
		}
	}
}