import { inject, autoinject, LogManager, bindable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';
import { Endpoint, Rest } from 'aurelia-api';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { SchoolClassCreateViewModel } from '../../models/schoolclasscreateview-model';
import { Helpers } from '../../services/helpers';
import { CourseViewModel } from '../../models/courseview-model';
import { SchoolYearViewModel } from '../../models/schoolyearview-model';

@autoinject
export class SchoolClassModalCreate {
    public model: SchoolClassCreateViewModel = null;
    private logger: Logger = LogManager.getLogger('SCHOOLCLASSMODALCREATE');
    private validationController: ValidationController | null = null;
    private saveDisabled: boolean = false;
    courses: CourseViewModel[] = [];
    schoolyears: SchoolYearViewModel[] = [];

    private yearId: number | null = null;

    constructor(
        @inject(Endpoint.of('api')) private api: Rest,
        private dialog: DialogController,
        private validationControllerFactory: ValidationControllerFactory) {

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.addRenderer(new BootstrapFormRenderer());
    }

    async activate(params: any) {
        this.logger.debug("activate");

		if (params && params.yearId) {
			this.yearId = params.yearId;
		}

		this.model = new SchoolClassCreateViewModel();
		this.model.coursePinCode = Helpers.GenerateRandomPincode();
        await this.fetchCourses();
        await this.fetchSchoolyears();
    }

    async fetchCourses() {
        this.logger.debug('fetchCourses');

        await this.api.find('/Course/GetAll')
            .then((data: Array<CourseViewModel>) => {
                this.courses = data;
                this.logger.debug('fetchCourses success');
            }).catch(async (err) => {
                this.logger.debug('Error fetchCourses: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

    async fetchSchoolyears() {
        this.logger.debug('fetchSchoolYears');

        await this.api.find('/SchoolYear/GetAll')
            .then((data: Array<SchoolYearViewModel>) => {
                this.schoolyears = data;

                if (this.yearId) {
                    this.model.schoolYearId = this.yearId;
                }

                this.logger.debug('Success fetchSchoolYears');
            }).catch(async (err) => {
                this.logger.debug('Error fetchSchoolYears: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

    async cancel() {
        this.logger.debug('cancel');
        await this.dialog.cancel();
    }

    async save() {
        this.logger.debug('save');
        this.saveDisabled = true;

        const validation: ControllerValidateResult = await this.validationController.validate();

        if (validation.valid) {
            await this.api.post('/SchoolClass/Insert', this.model)
                .then(async (data: any) => {
                    this.logger.debug('Success save: ', data);
                    this.saveDisabled = false;
                    await this.dialog.ok(data);
                }).catch(async (err) => {
                    this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                    this.saveDisabled = false;
                });
        } else {
            this.saveDisabled = false;
        }
    }

}
