import { autoinject, customElement } from 'aurelia-framework';
import { AuthService } from "aurelia-authentication";
import { SharedState } from "./../../state/shared-state";
import { RoleStep } from '../../services/RoleStep';

@autoinject
@customElement('user-block')
export default class {
    protected authProfileUrl: string | null = null;

    constructor(
		private authService: AuthService,
		private roleStep: RoleStep,
		protected sharedState: SharedState) {
		this.authService = authService;
    }

	public async attached() {

	}

    public login() {
    }

	public logout() {
		return this.authService.logout();
    }
}