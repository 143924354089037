// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';


export class EmailTemplateViewModel {
    subject: string = null;
	senderName: string = null;
	senderEmail: string = null;
	htmlContent: string = null;
	number
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        
	}
}