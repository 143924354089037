import { autoinject, bindable, customElement } from 'aurelia-framework';

@autoinject
@customElement('boolean-icon')
export default class {
    @bindable value: boolean | false = false;

	constructor() {
	}

	public async attached() {}
}