import { Rest, Endpoint } from 'aurelia-api';
import { autoinject, inject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { DialogController } from 'aurelia-dialog';
import { Helpers } from '../../services/helpers';
import { TopicCreateViewModel } from './../../models/topiccreateview-model'
import { CourseViewModel } from '../../models/courseview-model';

@autoinject
export class TopicsModalCreate {
	private validationController: ValidationController | null = null;
	private logger: Logger = LogManager.getLogger('TOPICMODALCREATE');
	public model: TopicCreateViewModel = null;
	private saveDisabled: boolean = false;
	private courses: CourseViewModel[] = [];
	public courseId: number | null = null;

	constructor(
		@inject(Endpoint.of('api'))
		private api: Rest,
		private validationControllerFactory: ValidationControllerFactory,
		private dialogController: DialogController) {
		this.validationController = validationControllerFactory.createForCurrentScope();
		this.validationController.addRenderer(new BootstrapFormRenderer());
	}

	async activate(params: any) {
		this.logger.debug("activate");
		this.model = new TopicCreateViewModel();

		// optional
		if (params && params.courseId) {
			this.courseId = params.courseId;
		}

		await this.getOrderNumber();
		await this.getCourses();
	}

	async getOrderNumber() {
		this.logger.debug('getOrderNumber');

		if (this.courseId) {
			await this.api.find('/Topic/GetMaxOrder', this.courseId)
				.then((data: number) => {
					this.model.order = data;
					this.logger.debug('Success getOrderNumber: ', data);
				}).catch(async (err) => {
					this.logger.debug('Error getOrderNumber: ', await err.json());
					await Helpers.ParseValidationError(err, this.validationController, this.model);
				});
		} else {
			this.model.order = 1;
		}

	}

	async getCourses() {
		this.logger.debug('getCourses');

		await this.api.find('/Course/GetAll')
			.then((data: Array<CourseViewModel>) => {
				this.courses = data;

				// set the courseId if it's provided
				if (this.courseId) {
					this.model.courseId = this.courseId;
				}

				this.logger.debug('Success getCourses: ', data);
			}).catch(async (err) => {
				this.logger.debug('Error getCourses: ', await err.json());
				await Helpers.ParseValidationError(err, this.validationController, this.model);
			});
	}

	async save() {
		this.logger.debug('save');
		this.saveDisabled = true;

		const validation: ControllerValidateResult = await this.validationController.validate();

		if (validation.valid !== false) {
			await this.api.create('/Topic/Insert', this.model)
				.then((data: TopicCreateViewModel) => {
					this.saveDisabled = false;
					this.logger.debug('Success save: ', data);
					this.dialogController.ok(data);
				}).catch(async (err) => {
					this.saveDisabled = false;
					this.logger.debug('Error save: ', err);
					await Helpers.ParseValidationError(err, this.validationController, this.model);
				});
		} else {
			this.saveDisabled = false;
		}
	}

	async cancel() {
		await this.dialogController.cancel();
	}
}