import { PLATFORM } from 'aurelia-pal';

export function configure(config) {
	config.globalResources([
		PLATFORM.moduleName('./elements/history-back'),
		PLATFORM.moduleName('./elements/loading-indicator'),
		PLATFORM.moduleName('./elements/server-error-display'),
		PLATFORM.moduleName('./elements/login-panel'),
		PLATFORM.moduleName('./value-converters/object-to-string'),
		PLATFORM.moduleName('./value-converters/numeral-vc'),
		PLATFORM.moduleName('./value-converters/array-vc'),
		PLATFORM.moduleName('./value-converters/sort-vc'),
		PLATFORM.moduleName('./value-converters/enum-vc'),
		PLATFORM.moduleName('./value-converters/limit-vc'),
		PLATFORM.moduleName('./value-converters/bool-vc'),
	]);
}