import { inject, autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { Endpoint, Rest } from 'aurelia-api';
import { DialogService } from 'aurelia-dialog';
import { ProfileDialog_Update } from './profile-modal-update';
import { ChangePasswordDialog } from '../password/change-password';
import { UserViewModel } from '../../models/userview-model';

@autoinject
export class Profile {
	private logger: Logger = LogManager.getLogger('PROFILE');
	private editPasswordDisabled: boolean | null = false;
	private editProfileDisabled: boolean | null = false;
	private model: UserViewModel | null = null;

	private isRequesting: boolean = true;

	constructor(
		@inject(Endpoint.of('api')) private apiEndpoint: Rest,
		private dialogService: DialogService) {
	}

	async activate() {
		this.logger.debug("activate")

		await this.getProfile();
	}

	async getProfile() {
		this.isRequesting = true;
		this.logger.debug('getProfile');

		await this.apiEndpoint.find('/User/GetCurrentUser').then(async (data: UserViewModel) => {
			this.model = new UserViewModel(data);
			this.isRequesting = false;
		});
	}

	async editProfile() {
		this.editProfileDisabled = true;

		this.logger.debug('editProfile');

		// Only edit profile if there's an actual profile loaded
		if (this.model != null) {
			await this.dialogService.open({ viewModel: ProfileDialog_Update, model: { userId: this.model.id } })
				.whenClosed(async response => {
					this.logger.debug('editProfile', response);

					if (!response.wasCancelled) {
						await this.getProfile();
					}
				}).catch((error) => {
					this.logger.debug('editProfile: error', error);
				});

			this.editProfileDisabled = false;
		}
	}

	async editPassword() {
		this.editPasswordDisabled = true;
		this.logger.debug('editPassword');

		if (this.model != null) {
			var popupResult = await this.dialogService.open({ viewModel: ChangePasswordDialog })
				.whenClosed(async response => {
					this.logger.debug('editPassword', response);

					if (!response.wasCancelled) {

					}
				}).catch((error: any) => {
					this.logger.debug('editPassword: error', error);
				});
		}

		this.editPasswordDisabled = false;
	}
}