import { valueConverter } from 'aurelia-framework';

/**
 * Converts a enum to the value
 * Can be used for enums and shortlist.
 * @example Enum        Insert into the VIEW: ${htmlFragment.fragmentPart | keyToValue:fragmentParts}
 * @example ShortList   Insert into the VIEW: ${htmlFragment.languageId | keyToValue:sl.languages}
 */
@valueConverter('enum')
export class EnumToValueValueConverter {
	toView(key: string, enumKeyValues: any, toLowerCase: boolean = false): string {

		if (enumKeyValues) {
			const keyValuePair = enumKeyValues.find(myObj => myObj.id === key);
			if (keyValuePair) {
				if (toLowerCase) {
					return keyValuePair.name.toLowerCase();
				} else {
					return keyValuePair.name;
				}
			}
		}

		return key;
	}
}

@valueConverter('courseTypeEnum')
export class CourseTypeEnumToValueValueConverter {
	toView(key: number): string {
		switch (key) {
			case 0:
				return "Cursus";
			case 1:
				return "Examen";
			default:
				return "";
		}
	}
}

@valueConverter('checkTypeEnum')
export class CheckTypeEnumToValueValueConverter {
	toView(key: number): string {
		switch (key) {
			case 0:
				return "Aanmaken";
			case 1:
				return "Verwijderen";
			case 1:
				return "Hernoemen";
			case 1:
				return "Financiële jaar aanmaken";
			default:
				return "";
		}
	}
}