// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';


export class ServiceEndpointCreateViewModel {
    createdBy: string = null;
	createdOn: Date = new Date(0);
	name: string = null;
	apiCommand: string = null;
	uri: string = null;
	entity: string = null;
	filter: string = null;
	selectedFields: string = null;
	isXMLCall: boolean = false;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('createdBy').displayName('createdBy').maxLength(250)
			.ensure('name').displayName('Naam').required().maxLength(128)
			.on(this);
	}
}