import { inject, autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';
import { Endpoint, Rest } from 'aurelia-api';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { CourseViewModel } from '../../models/courseview-model';
import { Helpers } from '../../services/helpers';

@autoinject
export class CoursesModalDelete {
	private logger: Logger = LogManager.getLogger('COURSEMODALDELETE');
	public model: CourseViewModel;
	private validationController: ValidationController | null = null;
	private saveDisabled: boolean = false;

	constructor(@inject(Endpoint.of('api')) private api: Rest,		
		public dialogController: DialogController,
		private validationControllerFactory: ValidationControllerFactory) {

		this.validationController = validationControllerFactory.createForCurrentScope();
		this.validationController.addRenderer(new BootstrapFormRenderer());
	}

	async activate(params: any) {
        this.logger.debug('activate');

        await this.api.findOne('/Course/GetById', params.courseId)
			.then((data: CourseViewModel) => {
                this.model = data;
                this.logger.debug('Success activate');
            }).catch(async (err) => {
                this.logger.debug('Error activate: ', await err.json());
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
	}

    async delete(id) {
        this.logger.debug('delete');
        this.saveDisabled = true;

        const validation: ControllerValidateResult = await this.validationController.validate();

		if (validation.valid) {
			this.api.destroy('/Course/DeleteById', id)
				.then(async (data: any) => {
					this.saveDisabled = false;
					this.logger.debug('Success delete: ', data);
					await this.dialogController.ok(data);
                }).catch(async (err) => {
                    this.saveDisabled = false;
                    this.logger.debug('Error delete: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                });
		} else {
			this.saveDisabled = true;
		}
	}

	async cancel() {
		await this.dialogController.cancel();
	}
}