import { validationMessages, ValidationRules } from 'aurelia-validation';


export class ValidateConfig {
    configure() {
        validationMessages['required'] = `Het veld \${$displayName} is verplicht`;
        validationMessages['maxLength'] = `Het veld \${$displayName} mag maximaal \${$config.length} tekens bevatten.`;
        validationMessages['minLength'] = `Het veld \${$displayName} moet minimaal \${$config.length} tekens bevatten.`;
        validationMessages['email'] = `Het veld \${$displayName} bevat een ongeldig mailadres.`;
        validationMessages['minItems'] = `Er moet minimaal 1 \${$displayName} aanwezig zijn.`;

        ValidationRules.customRule(
            'date',
            (value, obj) => value === null || value === undefined || value instanceof Date,
            `\${$displayName} moet een datum zijn.`
        );
		
        ValidationRules.customRule(
            'longRange',
            (value: number, obj, min: number, max: number) => value === null || value === undefined
                || Number.isInteger(+value) && +value >= min && value <= max,
            `\${$displayName} is verplicht en moet een waarde zijn tussen \${$config.min} en \${$config.max}.`,
            (min, max) => ({ min, max })
        );

        ValidationRules.customRule(
            'matchesProperty',
            (value, obj, otherPropertyName) =>
                value === null
                || value === undefined
                || value === ''
                || obj[otherPropertyName] === null
                || obj[otherPropertyName] === undefined
                || obj[otherPropertyName] === ''
                || value === obj[otherPropertyName],
            '${$displayName} moet gelijk zijn aan ${$getDisplayName($config.otherPropertyName)}', otherPropertyName => ({ otherPropertyName })
		);

		ValidationRules.customRule(
			'matchesValue',
			(value, obj, arg) => value === arg,
			'${$displayName} moet gelijk zijn aan ${$config.arg}', arg => ({ arg })
		);

        ValidationRules.customRule(
            'startAfterEndDatetime',
            (value, obj, otherPropertyName) =>
                value === null
                || value === undefined
                || value === ''
                || obj[otherPropertyName] === null
                || obj[otherPropertyName] === undefined
                || obj[otherPropertyName] === ''
                || new Date(value) < new Date(obj[otherPropertyName]),
            'De \${$getDisplayName($config.otherPropertyName)} is groter of gelijk aan de \${$displayName}.', otherPropertyName => ({ otherPropertyName })
        );

        ValidationRules.customRule(
            'maxDuration24',
            (value, obj, otherPropertyName) =>
                value === null
                || value === undefined
                || value === ''
                || obj[otherPropertyName] === null
                || obj[otherPropertyName] === undefined
                || obj[otherPropertyName] === ''
                || (new Date(value).getTime() - new Date(obj[otherPropertyName]).getTime()) / 60000 < 1440,
            'De totale duur is meer dan 24 uur.', otherPropertyName => ({ otherPropertyName })
        );
    }
}