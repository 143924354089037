import { HttpClient } from 'aurelia-fetch-client';
import { autoinject } from 'aurelia-framework';
import { AppSettingsViewModel } from '../models/appsettingsview-model'
import { status, parseError } from '../services/service-helper';

const CONFIG_PATH = '/api/AppSettings';
@autoinject
export class SharedState {
	// post login nav instruction: store target url when redirecting to login page
	public postLoginNavInstr: any;

	public filtersSidebarPinned: boolean = false;
	public filtersSidebarCollapsed: boolean = false;
	public http: HttpClient | null = null;
	public currentUserId: string | null = null;
	private AppSettings: AppSettingsViewModel = null;

	constructor(http: HttpClient) {
		this.http = http;
	}

	public async getAppSettings(): Promise<AppSettingsViewModel> {
		if (!this.AppSettings) {
			await this.loadAppSettings();
		}

		return this.AppSettings;
	}

	private async loadAppSettings(): Promise<AppSettingsViewModel> {
		const headersConfig = {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		};

		let config: AppSettingsViewModel = await this.http.fetch(`${CONFIG_PATH}`, {
			method: 'GET',
			headers: new Headers(headersConfig)
		})
			.then(status)
			.catch(parseError);

		this.AppSettings = config;

		return config;
	}
}