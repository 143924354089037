import { Endpoint, Rest } from 'aurelia-api';
import { DialogService } from 'aurelia-dialog';
import { autoinject, inject, LogManager } from "aurelia-framework";
import { Logger } from 'aurelia-logging';
import { HttpError } from "../../models/general";
import { CourseIndexViewModel } from '../../models/courseindexview-model';
import { Helpers } from "../../services/helpers";
import { CoursesModalDelete } from '../courses/courses-modal-delete';
import { CoursesModalUpdate } from '../courses/courses-modal-update';
import { CoursesModalCreate } from '../courses/courses-modal-create';
import { TopicsModalCreate } from '../topics/topics-modal-create';
import { TopicsModalUpdate } from '../topics/topics-modal-update';
import { TopicsModalDelete } from '../topics/topics-modal-delete';
import { AssignmentsModalDelete } from '../assignments/assignments-modal-delete';
import { AssignmentsModalUpdate } from '../assignments/assignments-modal-update';
import { AssignmentsModalCreate } from '../assignments/assignments-modal-create';
import { SubAssignmentsModalCreate } from '../subassignments/subassignments-modal-create';
import { SubAssignmentsModalDelete } from '../subassignments/subassignments-modal-delete';
import { SubAssignmentsModalUpdate } from '../subassignments/subassignments-modal-update';
import { AnswersModalDelete } from '../answers/answers-modal-delete';
import { AnswersModalUpdate } from '../answers/answers-modal-update';
import { AnswersModalCreate } from '../answers/answers-modal-create';

import { AnswerViewModel } from '../../models/answerview-model';
import { SubAssignmentViewModel } from '../../models/subassignmentview-model';
import { AssignmentViewModel } from '../../models/assignmentview-model';
import { TopicViewModel } from '../../models/topicview-model';
import { CourseViewModel } from '../../models/courseview-model';

@autoinject
export class CoursesTree {
	private logger: Logger = LogManager.getLogger('COURSESTREE');
	private isRequesting: boolean = true;
	private serverError: HttpError | null = null;
	private model: Array<CourseIndexViewModel> | null = null;
	private search: string | null = null;

	constructor(
		@inject(Endpoint.of('api'))
		private api: Rest,
		private dialogService: DialogService) { }

	async bind() {
		await this.getModel();
	}

	async getModel() {
		this.logger.debug('getModel');
        this.isRequesting = true;

		await this.api.find('/Course/GetAllInclusive')
			.then((data: Array<CourseIndexViewModel>) => {
                this.model = data;
                this.logger.debug('Success getModel');
            }).catch(async (error) => {
                this.logger.debug('Error getModel: ', await error.json());
            });

        this.isRequesting = false;
	}

	async newCourse() {
		this.logger.debug('newCourse');
		this.dialogService.open({ viewModel: CoursesModalCreate }).whenClosed(response => this.onClose(response));
	}

	async editCourse(id) {
		this.logger.debug('editCourse');
		this.dialogService.open({ viewModel: CoursesModalUpdate, model: { courseId: id } }).whenClosed(response => this.onClose(response));
	}

	async copyCourse(id) {
		this.logger.debug('copyCourse');
		this.isRequesting = true;

		await this.api.find('/Course/Copy', id)
			.then(async (data: CourseViewModel) => {
				await this.getModel();
				this.logger.debug('Success Course');
			}).catch(async (error) => {
				this.logger.debug('Error Course: ', await error.json());
			});

		this.isRequesting = false;
	}

	async deleteCourse(id) {
		this.logger.debug('deleteCourse');
		this.dialogService.open({ viewModel: CoursesModalDelete, model: { courseId: id } }).whenClosed(response => this.onClose(response));
	}

    async newTopic(courseId) {
        this.logger.debug('newTopic');
		this.dialogService.open({ viewModel: TopicsModalCreate, model: { courseId: courseId } }).whenClosed(response => this.onClose(response));
    }

    async editTopic(id) {
        this.logger.debug('editTopic');
		this.dialogService.open({ viewModel: TopicsModalUpdate, model: { topicId: id } }).whenClosed(response => this.onClose(response));
    }

	async copyTopic(id) {
		this.logger.debug('copyTopic');
		this.isRequesting = true;

		await this.api.find('/Topic/Copy', id)
			.then(async (data: TopicViewModel) => {
				await this.getModel();
				this.logger.debug('Success Topic');
			}).catch(async (error) => {
				this.logger.debug('Error Topic: ', await error.json());
			});

		this.isRequesting = false;
	}

    async deleteTopic(id) {
        this.logger.debug('deleteTopic');
		this.dialogService.open({ viewModel: TopicsModalDelete, model: { topicId: id } }).whenClosed(response => this.onClose(response));
    }

    async newAssignment(topicId) {
        this.logger.debug('newAssignment');
		this.dialogService.open({ viewModel: AssignmentsModalCreate, model: { topicId: topicId }}).whenClosed(response => this.onClose(response));
    }

    async editAssignment(id) {
        this.logger.debug('editAssignment');
		this.dialogService.open({ viewModel: AssignmentsModalUpdate, model: { assignmentId: id }}).whenClosed(response => this.onClose(response));
    }

	async copyAssignment(id) {
		this.logger.debug('copyAssignment');
		this.isRequesting = true;

		await this.api.find('/Assignment/Copy', id)
			.then(async (data: AssignmentViewModel) => {
				await this.getModel();
				this.logger.debug('Success SubAssignment');
			}).catch(async (error) => {
				this.logger.debug('Error SubAssignment: ', await error.json());
			});

		this.isRequesting = false;
	}

    async deleteAssignment(id) {
        this.logger.debug('deleteAssignment');
		this.dialogService.open({ viewModel: AssignmentsModalDelete, model: { assignmentId: id } }).whenClosed(response => this.onClose(response));
    }

    async newSubAssignment(assignmentId) {
        this.logger.debug('newAssignment');
		this.dialogService.open({ viewModel: SubAssignmentsModalCreate, model: { assignmentId: assignmentId }}).whenClosed(response => this.onClose(response));
    }

    async editSubAssignment(id) {
        this.logger.debug('editSubAssignment');
		this.dialogService.open({ viewModel: SubAssignmentsModalUpdate, model: { subAssignmentId: id }}).whenClosed(response => this.onClose(response));
    }

	async copySubAssignment(id) {
		this.logger.debug('copySubAssignment');
		this.isRequesting = true;

		await this.api.find('/SubAssignment/Copy', id)
			.then(async (data: SubAssignmentViewModel) => {
				await this.getModel();
				this.logger.debug('Success copySubAssignment');
			}).catch(async (error) => {
				this.logger.debug('Error copySubAssignment: ', await error.json());
			});

		this.isRequesting = false;
	}

    async deleteSubAssignment(id) {
        this.logger.debug('deleteSubAssignment');
		this.dialogService.open({ viewModel: SubAssignmentsModalDelete, model: { subAssignmentId: id }}).whenClosed(response => this.onClose(response));
    }

	async newAnswer(subAssignmentId) {
		this.logger.debug('newAnswer');
		this.dialogService.open({ viewModel: AnswersModalCreate, model: { subAssignmentId: subAssignmentId }}).whenClosed(response => this.onClose(response));
	}

	async editAnswer(id) {
		this.logger.debug('editAnswer');
		this.dialogService.open({ viewModel: AnswersModalUpdate, model: { answerId: id } }).whenClosed(response => this.onClose(response));
	}

	async copyAnswer(id) {
		this.logger.debug('copyAnswer');
		this.isRequesting = true;

		await this.api.find('/Answer/Copy', id)
			.then(async (data: AnswerViewModel) => {
				await this.getModel();
				this.logger.debug('Success copyAnswer');
			}).catch(async (error) => {
				this.logger.debug('Error copyAnswer: ', await error.json());
			});

		this.isRequesting = false;
	}

	async deleteAnswer(id) {
		this.logger.debug('deleteAnswer');
		this.dialogService.open({ viewModel: AnswersModalDelete, model: { answerId: id }}).whenClosed(response => this.onClose(response));
	}

	async onClose(response) {
		if (!response.wasCancelled) {
			await this.getModel();
			return response.output;
		} else {
			return null;
		}
	}
}