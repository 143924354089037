// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { AnswerViewModel } from './answerview-model';


export class AnswerSelectedFilterViewModel {
    id: number = 0;
	answerId: number = 0;
	answer: AnswerViewModel = null;
	filterFieldName: string = null;
	filterFieldOperator: string = null;
	filterFieldValue: string = null;
	createdOn: Date = new Date(0);
	modifiedOn: Date = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        
	}
}