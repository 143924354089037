import { autoinject, computedFrom, LogManager, inject } from "aurelia-framework";
import { Logger } from 'aurelia-logging';
import { AuthService } from "aurelia-authentication";
import { Router } from "aurelia-router";
import { RoleStep } from "../../services/RoleStep";
import { Constants } from "../../models/constants";
import { Endpoint, Rest } from "aurelia-api";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class Home {
    logger: Logger = LogManager.getLogger('HOME');
	private profile: any;
	private role: string = Constants.roleStudent;

	private loginVisible: boolean = true;

	constructor(
		private auth: AuthService,
		private roleStep: RoleStep,
		private events: EventAggregator,
		@inject(Endpoint.of('api')) private api: Rest)
	{
		this.auth = auth;
		this.roleStep = roleStep;

		this.events.subscribe('authentication-change', authenticated => {
			if (authenticated) {
				this.getCurrentUser();
			}
		});
	}

	@computedFrom('auth.authenticated')
	get isAuthenticated() {
		return this.auth.authenticated;
	}

	async attached() {
        this.logger.debug("attached");
		if (this.auth.authenticated) {
			this.getCurrentUser();
		}
	}

	async getCurrentUser() {
		await this.api.findOne('/Account/GetCurrentUser', '').then(data => {
			this.profile = data;
            this.logger.debug('Success getCurrentUser');
        }).catch(async (err) => {
            this.logger.debug('Error getCurrentUser: ', err);
        });
	}

	async showLogin() {
		this.loginVisible = !this.loginVisible;
	}

	async setRole(passRole: string) {
		// switch role, which will trigger the bindable change on login-panel.ts
		this.role = passRole;
	}
}
