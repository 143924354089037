import { Router, NavigationInstruction } from "aurelia-router";
import { autoinject, computedFrom } from "aurelia-framework";
import { AuthService } from "aurelia-authentication";
import { RoleStep } from "../../services/RoleStep";

@autoinject
export class Navmenu {
	constructor(private router: Router,	private auth: AuthService, private roleStep: RoleStep) {
        this.auth = auth;
        this.router = router;
		this.roleStep = roleStep;
	}

    showForRole(navItem: NavigationInstruction): boolean {
        return this.roleStep.UserCanNavigateRole(navItem);
    }

	@computedFrom('auth.authenticated')
	get isAuthenticated() {
		return this.auth.authenticated;
	}

    openCollapsed(row) {
        row.isOpened = !row.isOpened ? true : false;
    }
}
