// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';


export class UserClaimsViewModel {
    id: string = null;
	division: string = null;
	claimsCompany: string = null;
	claimsUserData: string = null;
	claimsName: string = null;
	claimsEmail: string = null;
	claimsDisplayName: string = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        
	}
}