// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { ApplicationUserViewModel } from './applicationuserview-model';
import { SchoolClassViewModel } from './schoolclassview-model';


export class StudentCreateViewModel {
    id: number = 0;
	applicationUserId: string = null;
	applicationUser: ApplicationUserViewModel = null;
	firstname: string = null;
	lastname: string = null;
	schoolClassId: number = 0;
	schoolClass: SchoolClassViewModel = null;
	isActive: boolean = true;
	lastLoginDate: Date = null;
	firstLoginDate: Date = null;
	email: string = null;
	fullname: string = null;
	password: string = null;
	confirmPassword: string = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('firstname').displayName('Voornaam').required()
			.ensure('lastname').displayName('Achternaam').required()
			.ensure('schoolClassId').displayName('Schoolklas').required()
			.ensure('email').displayName('E-mail').required()
			.ensure('password').displayName('Wachtwoord').required()
			.ensure('confirmPassword').displayName('Herhaal wachtwoord').required()
			.on(this);
	}
}