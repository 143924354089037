import { ErrorResponseModel, HttpError } from "../models/general";

export function status(response) {
	if (response.status >= 200 && response.status < 400) {
		if (response.status === 204) { // no content // for delete???
			return;
		}

		if (response.headers.get('content-type').includes('text/html')) {
			return response.text();
		}

		if (response.headers.get('content-type').includes('application/json')) {
			return response.json();
		}
	}

	let errorList: Array<ErrorResponseModel> = new Array<ErrorResponseModel>();
	const serverErrorMsg: string = 'Fout';
	errorList.push(new ErrorResponseModel({ errorMessage: 'Er is een fout opgetreden op de server:', property: null }));

	if (response instanceof Response) {
		switch (response.status) {
			case 400:
				errorList.push(new ErrorResponseModel({ errorMessage: `Foutcode: ${response.status}: Foute aanvraag`, property: null }));
				if (response.statusText) {
					errorList.push(new ErrorResponseModel({ errorMessage: `Melding ${response.statusText}`, property: null, statusCode: response.status }));
				}
				errorList.push(new ErrorResponseModel({ errorMessage: `Url: ${response.url}`, property: null }));

				return response.json().then(modelState => {
					for (var key in modelState) {
						var errorMsg = modelState[key];
						errorList.push(new ErrorResponseModel({ errorMessage: errorMsg, property: key }));
					}
					throw new HttpError(serverErrorMsg, errorList);
				}).catch(e => {
					//this.logger.debug('400 error.json() error:', e);
					throw new HttpError(serverErrorMsg, errorList);
				});

			case 401:
				errorList.push(new ErrorResponseModel({ errorMessage: `Foutcode: ${response.status}: Niet geautoriseerd`, property: null }));
				if (response.statusText) {
					errorList.push(new ErrorResponseModel({ errorMessage: `Melding ${response.statusText}`, property: null, statusCode: response.status }));
				}
				errorList.push(new ErrorResponseModel({ errorMessage: `Url: ${response.url}`, property: null }));

				throw new HttpError(serverErrorMsg, errorList);

			case 403:
				errorList.push(new ErrorResponseModel({ errorMessage: `Foutcode: ${response.status}: Geen rechten`, property: null }));
				if (response.statusText) {
					errorList.push(new ErrorResponseModel({ errorMessage: `Melding ${response.statusText}`, property: null, statusCode: response.status }));
				}
				errorList.push(new ErrorResponseModel({ errorMessage: `Url: ${response.url}`, property: null }));

				throw new HttpError(serverErrorMsg, errorList);

			case 404:
				errorList.push(new ErrorResponseModel({ errorMessage: `Foutcode: ${response.status}: Niet gevonden`, property: null }));
				if (response.statusText) {
					errorList.push(new ErrorResponseModel({ errorMessage: `Melding ${response.statusText}`, property: null, statusCode: response.status }));
				}
				errorList.push(new ErrorResponseModel({ errorMessage: `Url: ${response.url}`, property: null }));

				return response.json().then(modelState => {
					for (var key in modelState) {
						var errorMsg = modelState[key];
						errorList.push(new ErrorResponseModel({ errorMessage: errorMsg, property: key }));
					}
					throw new HttpError(serverErrorMsg, errorList);
				}).catch(e => {
					//this.logger.debug('404 error.json() error:', e);
					throw new HttpError(serverErrorMsg, errorList);
				});

			case 500:
				errorList.push(new ErrorResponseModel({ errorMessage: `Foutcode: ${response.status}: Interne serverfout`, property: null }));
				if (response.statusText) {
					errorList.push(new ErrorResponseModel({ errorMessage: `Melding ${response.statusText}`, property: null, statusCode: response.status }));
				}
				errorList.push(new ErrorResponseModel({ errorMessage: `Url: ${response.url}`, property: null }));

				return response.json().then(modelState => {
					for (var key in modelState) {
						var errorMsg = modelState[key];
						errorList.push(new ErrorResponseModel({ errorMessage: errorMsg, property: key }));
					}
					throw new HttpError(serverErrorMsg, errorList);
				}).catch(e => {
					//this.logger.debug('500 error.json() error:', e);
					throw new HttpError(serverErrorMsg, errorList);
				});

			default:
				errorList.push(new ErrorResponseModel({ errorMessage: `Foutcode: ${response.status}: Deze code is nog niet ondervangen in switch case..`, property: null }));
				if (response.statusText) {
					errorList.push(new ErrorResponseModel({ errorMessage: `Melding ${response.statusText}`, property: null, statusCode: response.status }));
				}
				errorList.push(new ErrorResponseModel({ errorMessage: `Url: ${response.url}`, property: null }));
				errorList.push(new ErrorResponseModel({ errorMessage: 'Onverwachte fout. Kijk in het foutenlog voor meer informatie. Status: ' + response.status + ' - ' + response.statusText, property: null }));
				throw new HttpError(serverErrorMsg, errorList);
		}
	} else {
		throw new HttpError(response, errorList);
	}
}


export function parseError(error) {
	if (!(error instanceof Error)) {
		return new Promise((resolve, reject) => reject(error.json()))
	}
	return error;
}