import { autoinject, inject, bindable, LogManager } from 'aurelia-framework';
import { Endpoint, Rest } from 'aurelia-api';
import { Logger } from 'aurelia-logging';
import { DialogController } from 'aurelia-dialog';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { SchoolClassViewModel } from '../../models/schoolclassview-model';
import { SchoolClassEditViewModel } from '../../models/schoolclasseditview-model';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { SchoolYearViewModel } from '../../models/schoolyearview-model';
import { CourseViewModel } from '../../models/courseview-model';
import { Helpers } from '../../services/helpers';
import { TeacherViewModel } from '../../models/teacherview-model';

@autoinject
export class SchoolClassModalUpdate {
	private id: number;
	public model: SchoolClassEditViewModel;
	public schoolyears: Array<SchoolYearViewModel> = [];
	public courses: Array<CourseViewModel> = [];
	public teachers: Array<TeacherViewModel> = [];
	private logger: Logger = LogManager.getLogger('SCHOOLYEARSMODALUPDATE');
	private validationController: ValidationController | null = null;
	private saveDisabled: boolean = false;

	constructor(
		@inject(Endpoint.of('api')) private api: Rest,
		private diaLog: DialogController,
		private validationControllerFactory: ValidationControllerFactory) {

		this.validationController = validationControllerFactory.createForCurrentScope();
		this.validationController.addRenderer(new BootstrapFormRenderer());
	}

    async activate(params: any) {
        this.logger.debug('activate');
		this.id = params.classId;

        await this.api.find('/SchoolClass/GetById', this.id)
			.then(async (data: SchoolClassViewModel) => {
				this.logger.debug('Success activate: ', data);
				this.model = new SchoolClassEditViewModel(data);
			}).catch(async (err) => {
				this.logger.debug('Error activate: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);		
			});

		await this.getSchoolyears();
		await this.getCourses();
	}

    async getSchoolyears() {
        this.logger.debug('fetchSchoolYears');

		await this.api.find('/SchoolYear/GetAll')
			.then((data: Array<SchoolYearViewModel>) => {
				this.schoolyears = data;
                this.logger.debug('Success fetchSchoolYears');
            }).catch(async (err) => {
                this.logger.debug('Error fetchSchoolYears: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
	}

    async getCourses() {
        this.logger.debug('fetchCourses');

		await this.api.find('/Course/GetAll')
			.then((data: Array<CourseViewModel>) => {
				this.courses = data;
                this.logger.debug('fetchCourses success');
            }).catch(async (err) => {
                this.logger.debug('Error fetchCourses: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
	}

	async cancel() {
		this.logger.debug('cancel');
		await this.diaLog.cancel();
	}

    async save() {
        this.logger.debug('save');
		this.saveDisabled = true;

		const validation: ControllerValidateResult = await this.validationController.validate();

		if (validation.valid) {
			await this.api.update('/SchoolClass/Update', this.id, this.model)
				.then(async (data: SchoolClassViewModel) => {
                    this.logger.debug('Success save: ', data);
					this.saveDisabled = false;
					//close modal
					await this.diaLog.ok(data);
				}).catch(async (err) => {
                    this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
					this.saveDisabled = false;
				});
		} else {
			this.saveDisabled = false;
		}
	}
}