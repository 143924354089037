import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, BindingEngine, customElement } from 'aurelia-framework';
import { SharedState } from "./../../state/shared-state";

@autoinject
@customElement('sidebar-right')
export default class {
	@bindable() title;
	subscription: any;

    constructor(
        protected sharedState: SharedState,
        private bindingEngine: BindingEngine,
        private ea: EventAggregator) {
    }

    private clickEvent = (event) => {
		if (!event.target.closest('#sidebar-right')) {
            if (!this.sharedState.filtersSidebarCollapsed) {
                this.sharedState.filtersSidebarCollapsed = true;
				this.outsideClickEvent(false);
			}
		}
	};

    public async attached() {
        this.subscription = this.bindingEngine
            .propertyObserver(this.sharedState, 'filtersSidebarCollapsed')
            .subscribe((newValue, oldValue) => {
                this.collapsedChanged(newValue, oldValue)
            });
    }

    public async detached() {
        this.subscription.dispose();
    }

	async close() {
		this.outsideClickEvent(false);
        this.sharedState.filtersSidebarCollapsed = true;
        this.sharedState.filtersSidebarPinned = false;
        this.ea.publish('rightMenuPinnedChanged', this.sharedState.filtersSidebarPinned);
	}

	async pin() {
		this.outsideClickEvent(false);
        this.sharedState.filtersSidebarPinned = !this.sharedState.filtersSidebarPinned;

        if (!this.sharedState.filtersSidebarPinned) {
			this.outsideClickEvent(true);
		}
        this.ea.publish('rightMenuPinnedChanged', this.sharedState.filtersSidebarPinned);
	}

	async outsideClickEvent(enable: boolean) {
		const removeClickListener = () => {
			document.removeEventListener('click', this.clickEvent, true);
		}

		if (enable) {
			document.addEventListener('click', this.clickEvent, true);
		} else {
			removeClickListener();
		}
	}

    async collapsedChanged(newValue, oldValue) {

		//if (oldValue !== undefined ) {
			if (!newValue) {
				//setTimeout(() => {
					this.outsideClickEvent(true);
				//}, 100);
			} //else {
			//	this.outsideClickEvent(false);
			//}
			////else if (newValue === true && oldValue === false) {
			////	this.ea.publish('rightMenuPinnedChanged', this.pinned);
			////}
		//}
	}
}