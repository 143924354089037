import { autoinject, bindable, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { HttpError } from '../../models/general';
import { UserViewModel } from '../../models/userview-model';

@autoinject
export class UserCard {
	@bindable item: UserViewModel | null = null;
	@bindable deleteUser;

	private logger: Logger = LogManager.getLogger('USERCARD');
	private serverError: HttpError | null = null;

	constructor() {}

	async delete() {
		this.logger.debug('deleteUser');

		// it's necessary to wrap this into an args object, else the passing of arguments does not work
		var args = { id: this.item.id }
		this.deleteUser(args);
	}
} 
