// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { SchoolYearViewModel } from './schoolyearview-model';
import { SchoolClassViewModel } from './schoolclassview-model';
import { CourseViewModel } from './courseview-model';
import { TopicViewModel } from './topicview-model';

import { TopicState } from './enums';

export class CourseSchoolClassSpecificsViewModel {
    id: number = 0;
	schoolYearId: number = 0;
	schoolYear: SchoolYearViewModel = null;
	schoolClassId: number = 0;
	schoolClass: SchoolClassViewModel = null;
	courseId: number = 0;
	course: CourseViewModel = null;
	topicId: number = 0;
	topic: TopicViewModel = null;
	deadLineDate: Date = null;
	topicState: TopicState = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        
	}
}