// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';


export class ResetPasswordViewModel {
    email: string = null;
	newPassword: string = null;
	confirmPassword: string = null;
	token: string = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('email').displayName('E-mail').required()
			.ensure('newPassword').displayName('Nieuw wachtwoord').required()
			.ensure('confirmPassword').displayName('Bevestig wachtwoord').required().satisfiesRule('matchesProperty', 'NewPassword')
			.on(this);
	}
}