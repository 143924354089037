import { valueConverter } from 'aurelia-framework';

/**
 * Cuts of all remaining characters and adds ...
 */

@valueConverter('limitTo')
export class LimitToValueConverter {
	toView(value: string, limit: number) {

		var returnValue: string = value;
		if (value && value.length > limit) {
			returnValue = returnValue.substr(0, limit);
			returnValue = returnValue + "...";
		}

		return returnValue;
	}
}
