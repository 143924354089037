// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { SubAssignmentViewModel } from './subassignmentview-model';
import { AnswerSelectedFieldViewModel } from './answerselectedfieldview-model';
import { AnswerSelectedFilterViewModel } from './answerselectedfilterview-model';


export class AnswerEditViewModel {
    id: number = 0;
	isXMLCall: boolean = false;
	uri: string = null;
	entity: string = null;
	subAssignmentId: number = 0;
	subAssignment: SubAssignmentViewModel = null;
	answerText: string = null;
	answerDescription: string = null;
	importance: number = 1;
	order: number = 0;
	studentIncorrectMessage: string = null;
	teacherIncorrectMessage: string = null;
	answerSelectedFields: AnswerSelectedFieldViewModel[] = [];
	answerSelectedFilters: AnswerSelectedFilterViewModel[] = [];
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        
	}
}