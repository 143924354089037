// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';

import { CourseType } from './enums';

export class CourseViewModel {
    id: number = 0;
	name: string = null;
	courseType: CourseType = null;
	showInQuickEntry: boolean = false;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('name').displayName('Naam').required()
			.ensure('courseType').displayName('Type').required()
			.on(this);
	}
}