import { Rest, Endpoint } from 'aurelia-api';
import { autoinject, inject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { DialogController } from 'aurelia-dialog';
import { Helpers } from '../../services/helpers';
import { CourseCreateViewModel } from './../../models/coursecreateview-model'
import { CourseType	} from '../../models/enums';

@autoinject
export class CoursesModalCreate {
	private validationController: ValidationController | null = null;
	private logger: Logger = LogManager.getLogger('COURSEMODALCREATE');
    public model: CourseCreateViewModel = null;

    private courseTypeKeys: string[];
    private courseTypeValues: number[];
    private courseTypeId: number = null; 

    private saveDisabled: boolean = false;

	constructor(
		@inject(Endpoint.of('api'))
		private api: Rest,
		private validationControllerFactory: ValidationControllerFactory,
		private dialogController: DialogController) {
			this.validationController = validationControllerFactory.createForCurrentScope();
			this.validationController.addRenderer(new BootstrapFormRenderer());
	}

    async activate() {
        this.logger.debug("activate");
        this.getEnumValues();
        this.model = new CourseCreateViewModel();
    }

    getEnumValues() {
		this.courseTypeKeys = ["Cursus", "Examen"];
		this.courseTypeValues = [0, 1];
    }

    async save() {
		this.logger.debug('save');
        this.saveDisabled = true;

        this.model.courseType = this.courseTypeId; // set enum

		const validation: ControllerValidateResult = await this.validationController.validate();

		if (validation.valid !== false) {
			await this.api.create('/Course/Insert', this.model)
				.then((data: CourseCreateViewModel) => {
					this.saveDisabled = false;
                    this.logger.debug('Success save: ', data);
					this.dialogController.ok(data);
                }).catch(async (err) => {
                    this.saveDisabled = false;
                    this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                });
		} else {
			this.saveDisabled = false;
		}
	}

	async cancel() {
		await this.dialogController.cancel();
	}
}