import { autoinject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';
import { HttpError } from "../../models/general";


@autoinject
export class ServerErrorDisplay {
	@bindable errorString: string | null = null;
    @bindable error: HttpError | null = null;

    constructor() { }
}