import { autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';
import { observable } from 'aurelia-binding';


@autoinject
export class ProfileEOLModal {
	private uri: string = "";
	private logger: Logger = LogManager.getLogger('PROFILEEOLMODAL');

	constructor(private dialogController: DialogController) {
	}

	async activate(params: any) {
		this.uri = params.uri;
		this.logger.debug("open ProfileEOLModal");
	}

	async close() {
		this.logger.debug("closing ProfileEOLModal");
		// just close it.
		this.dialogController.cancel();
	}
}
