import { Endpoint, Rest } from 'aurelia-api';
import { DialogService } from 'aurelia-dialog';
import { autoinject, inject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { HttpError } from '../../models/general';
import { Helpers } from "../../services/helpers";
import { StudentModalDelete } from './student-modal-delete';
import { StudentModalUpdate } from './student-modal-update';
import { Constants } from './../../models/constants';
import { UserViewModel } from '../../models/userview-model';

@autoinject
export class Students {
    private logger: Logger = LogManager.getLogger('STUDENTS');
    private isRequesting: boolean = true;
    private serverError: HttpError | null = null;
	private model: Array<UserViewModel> | null = null;
    private search: string | null = null;
    private selectedField: string = 'fullname';
    private ascending: boolean = true;
    private showPagination: boolean = true;
    private paginationSelectedPage: number = 1;
    private paginationTake: number = 15;
    private paginationPages: number = 0;

    constructor(
        @inject(Endpoint.of('api'))
        private api: Rest,
        private dialog: DialogService
    ) { }

    async bind() {
        await this.getModel();
    }

    async getModel() {
        this.logger.debug('getModel');
        this.isRequesting = true;

        await this.api.find('/User/GetAll', Constants.roleStudent)
			.then((data: Array<UserViewModel>) => {
                this.model = data;
                Helpers.sortModel(this.model, this.selectedField, this.ascending);

                this.paginationPages = Math.ceil(this.model.length / this.paginationTake);
                this.showPagination = this.paginationPages > 1;
                this.logger.debug('Success getModel');
			}).catch(async (err) => {
				var errorObj = await err.json();
				this.logger.debug('Error getModel: ', errorObj);

				// set errorMessage
				this.serverError = errorObj;
			});

        this.isRequesting = false;
    }


    sortModel(_class: Object[], field: string) {
        this.showPagination = false;
        this.selectedField = field;
        this.ascending === false ? this.ascending = true : this.ascending = false;
		this.paginationSelectedPage = 1;

        Helpers.sortModel(_class, this.selectedField, this.ascending);

        // make pagination control refresh because everyting in it is binding once
        setTimeout(() => {
            this.showPagination = this.paginationPages > 1;
        }, 100);
    }

    pageChanged($event, selectedPageNumber) {
        this.paginationSelectedPage = selectedPageNumber;
    }

    async edit(id) {
        this.logger.debug('edit');
		this.dialog.open({ viewModel: StudentModalUpdate, model: { studentId: id }}).whenClosed(response => this.onClose(response));
    }

    async delete(id) {
        this.logger.debug('delete');
		this.dialog.open({ viewModel: StudentModalDelete, model: { studentId: id }}).whenClosed(response => this.onClose(response));
    }

    async onClose(response) {
        if (!response.wasCancelled) {
            await this.getModel();
            return response.output;
        } else {
            return null;
        }
    }
}