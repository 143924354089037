import { inject, autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { Endpoint, Rest } from 'aurelia-api';
import { DialogService } from 'aurelia-dialog';
import { HttpError } from '../../models/general';
import { SharedState } from "../../state/shared-state";
import { UsersModalCreate } from './users-modal-create';
import { UsersModalUpdate } from './users-modal-update';
import { UsersModalDelete } from './users-modal-delete';
import { UserViewModel } from './../../models/userview-model';
import { Constants } from './../../models/constants';
import { Helpers } from '../../services/helpers';

@autoinject
export class Users {
	private logger: Logger = LogManager.getLogger('Users');
	private isRequesting: boolean = true;
	private serverError: HttpError | null = null;
	private model: Array<UserViewModel>;

	private search: string | null = null;
	private selectedField: string = 'email';
	private ascending: boolean = true;
	private showPagination: boolean = true;
	private paginationSelectedPage: number = 1;
	private paginationTake: number = 15;
	private paginationPages: number = 0;

	constructor(
		@inject(Endpoint.of('api')) private api: Rest,
		protected sharedState: SharedState,
		private dialogService: DialogService) {
	}

	async activate() {
		this.logger.debug("activate")
		await this.getModel();
	}

	async getModel() {
		this.logger.debug('getModel');
		this.isRequesting = true;
		await this.api.find('/User/GetAll', Constants.roleAdministrator)
			.then((data: Array<UserViewModel>) => {
				this.model = data;
				Helpers.sortModel(this.model, this.selectedField, this.ascending);
				this.paginationPages = Math.ceil(this.model.length / this.paginationTake);
				this.showPagination = this.paginationPages > 1;

				this.logger.debug('Success: getModel');
			}).catch(async (err) => {
				var errorObj = await err.json();
				this.logger.debug('Error getModel: ', errorObj);

				// set errorMessage
				this.serverError = errorObj;
			});

		this.isRequesting = false;
	}

	sortModel(_class: Object[], field: string) {
		this.showPagination = false;
		this.selectedField = field;
		this.ascending === false ? this.ascending = true : this.ascending = false;
		this.paginationSelectedPage = 1;
		Helpers.sortModel(_class, this.selectedField, this.ascending);

		// make pagination control refresh because everyting in it is binding once
		setTimeout(() => {
			this.showPagination = this.paginationPages > 1;
		}, 100);
	}

	pageChanged($event, selectedPageNumber) {
		this.paginationSelectedPage = selectedPageNumber;
	}

	async newItem() {
		this.logger.debug('newItem');
		this.dialogService.open({ viewModel: UsersModalCreate }).whenClosed(response => this.onClose(response));
	}

	async edit(id) {
		this.logger.debug('edit');
		this.dialogService.open({ viewModel: UsersModalUpdate, model: { userId: id } }).whenClosed(response => this.onClose(response));
	}

	async delete(userId: string) {
		this.logger.debug('delete');
		this.dialogService.open({ viewModel: UsersModalDelete, model: { userId: userId } }).whenClosed(response => this.onClose(response));
	}

	async onClose(response) {
		if (!response.wasCancelled) {
			await this.getModel();
			return response.output;
		} else {
			return null;
		}
	}
}