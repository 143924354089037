// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';


export class ActivityLogViewModel {
    date: Date = new Date(0);
	session: string = null;
	userId: string = null;
	schoolId: number = 0;
	schoolClassId: number = 0;
	schoolYearId: number = 0;
	courseId: number = 0;
	division: string = null;
	action: string = null;
	localDate: Date = new Date(0);
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        
	}
}