import { inject, autoinject, LogManager, bindable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';
import { Endpoint, Rest } from 'aurelia-api';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { Helpers } from '../../services/helpers';
import { CourseType } from '../../models/enums';
import { CourseViewModel } from '../../models/courseview-model';
import { CourseEditViewModel } from '../../models/courseeditview-model';

@autoinject
export class CoursesModalUpdate {
    @bindable id: number = 0;
    model: CourseEditViewModel = null;
    private logger: Logger = LogManager.getLogger('COURSEMODALUPDATE');
    private validationController: ValidationController | null = null;
    private saveDisabled: boolean = false;

    private courseTypeKeys: string[];
    private courseTypeValues: number[];
    private courseTypeId: number = null; 

    constructor(@inject(Endpoint.of('api')) private api: Rest,
        private validationControllerFactory: ValidationControllerFactory,
        private dialogController: DialogController) {
        this.validationController = validationControllerFactory.createForCurrentScope();

        this.validationController.addRenderer(new BootstrapFormRenderer());
    }

    async activate(params: any) {
        this.logger.debug('activate');
        this.id = params.courseId;

        this.getEnumValues();
        await this.getModel();
    }

    getEnumValues() {
		this.courseTypeKeys = ["Cursus", "Examen"];
		this.courseTypeValues = [0, 1];
    }

    async getModel() {
        this.logger.debug('getModel');

        await this.api.find('/Course/GetById', this.id)
            .then(async (data: CourseViewModel) => {
                this.logger.debug('Success getModel: ', data);
                this.model = new CourseViewModel(data);
                this.courseTypeId = data.courseType; // set enum
            }).catch(async (err) => {
                this.logger.debug('Error getModel: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }


    async save() {
        this.logger.debug('save');
        this.saveDisabled = true;

        this.model.courseType = this.courseTypeId; // set enum
        const validation: ControllerValidateResult = await this.validationController.validate();

        if (validation) {
            await this.api.update('/Course/Update', this.id, this.model)
                .then((data: CourseEditViewModel) => {
                    this.logger.debug('Success save: ' + data);
                    this.saveDisabled = false;
                    this.dialogController.ok(data);
                }).catch(async (err) => {
                    this.saveDisabled = false;
                    this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                });
        }
    }

    cancel() {
        this.dialogController.cancel();
    }
}