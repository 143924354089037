import { valueConverter } from 'aurelia-framework';
import * as numeral from 'numeral';

@valueConverter('count')
export class CountValueConverter {
    toView(array: Array<any>, format: string = '0'): string {
        const countValues = array ? array.length : 0;
        return numeral(countValues).format(format);
    }
}

@valueConverter('sum')
export class SumValueConverter {
    toView(array: Array<any>, property: string, format: string = '0,0.00'): string {
        // + operator for casting to Number
        const sumValues = array ? array.map(item => item.hasOwnProperty(property) ? +item[property] : 0).reduce((prev: number, next: number) => +prev + +next, 0) : 0;
        return numeral(sumValues).format(format);
    }
}

@valueConverter('dateOrder')
export class DateOrderValueConverter {
    toView(array, property, direction, emptyBottom) {
        if (!array)
            return array;
        let factor = direction.match(/^desc*/i) ? 1 : -1;
        var retvalue = array.sort((a, b) => {
            a = new Date(a[property]);
            b = new Date(b[property]);
            return a > b ? -factor : a < b ? factor : 0;
        });
        return retvalue;
    }
}

@valueConverter('timeOrder')
export class TimeOrderValueConverter {
    toView(array, property, direction, emptyBottom) {
        if (!array)
            return array;
        let factor = direction.match(/^desc*/i) ? 1 : -1;
        var retvalue = array.sort((a, b) => {
            a = new Date('1/1/1999 ' + a[property]);
            b = new Date('1/1/1999 ' + b[property]);
            return a > b ? -factor : a < b ? factor : 0;
        });
        return retvalue;
    }
}

@valueConverter('filterOnProperty')
export class FilterOnPropertyValueConverter {
	toView(array: {}[], property: string, exp: any) {

        if (array === undefined || array === null || property === undefined || !exp) {
            return array;
        }
        return array.filter((item) => item[property] === exp);
    }
}


@valueConverter('filterSearchOnProperty')
export class FilterSearchOnPropertyValueConverter {
    toView(array: {}[], property: string, exp: any) {
		if (array === undefined || array === null || property === undefined || !exp) {
			return array;
		}
		return array.filter((item) => property.split('.').reduce((o, i) => o[i], item).toLowerCase().includes(exp));
	}
}

@valueConverter('duplicateItems')
export class DuplicateItemsValueConverter {
    toView(array: Array<any>) {
        let newArray = [];

        for (var i = 0; i < array.length; ++i) {
            newArray.push(array[i]);
            newArray.push(array[i]);
        }

        return newArray;
    }
}

@valueConverter('chunk')
export class ChunkValueConverter {
	toView(array: Array<any>, chunkSize: number) {
		var R = [];

		if (array) {
			for (var i = 0; i < array.length; i += chunkSize) {
				R.push(array.slice(i, i + chunkSize));
			}
		}

		return R;
	}
}

@valueConverter('pagination')
export class PaginationValueConverter {
	toView(array: Array<any>, page: number, pageSize: number) {
		const paginate = function (array, index, size) {
			// transform values
			index = Math.abs(parseInt(index));
			index = index > 0 ? index - 1 : index;
			size = parseInt(size);
			size = size < 1 ? 1 : size;

			// filter
			return [...(array.filter((value, n) => {
				return (n >= (index * size)) && (n < ((index + 1) * size))
			}))]
		}

		return paginate(array, page, pageSize);
	}
}

