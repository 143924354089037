// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { ApplicationUserViewModel } from './applicationuserview-model';
import { SchoolViewModel } from './schoolview-model';


export class TeacherEditViewModel {
    id: number = 0;
	applicationUserId: string = null;
	applicationUser: ApplicationUserViewModel = null;
	firstname: string = null;
	lastname: string = null;
	schoolId: number = 0;
	school: SchoolViewModel = null;
	lastLoginDate: Date = null;
	firstLoginDate: Date = null;
	email: string = null;
	fullname: string = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('firstname').displayName('Voornaam').required()
			.ensure('lastname').displayName('Achternaam').required()
			.ensure('schoolId').displayName('School').required()
			.ensure('email').displayName('E-mail').required()
			.on(this);
	}
}