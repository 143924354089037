import { inject, autoinject, LogManager, bindable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';
import { Endpoint, Rest } from 'aurelia-api';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { Helpers } from '../../services/helpers';
import { TopicViewModel } from '../../models/topicview-model';
import { TopicEditViewModel } from '../../models/topiceditview-model';
import { CourseViewModel } from '../../models/courseview-model';

@autoinject
export class TopicsModalUpdate {
    @bindable id: number = 0;
    model: TopicEditViewModel = null;
    private logger: Logger = LogManager.getLogger('TOPICMODALUPDATE');
    private validationController: ValidationController | null = null;
    private saveDisabled: boolean = false;
    private courses: Array<CourseViewModel> = [];

    constructor(@inject(Endpoint.of('api')) private api: Rest,
        private validationControllerFactory: ValidationControllerFactory,
        private dialogController: DialogController) {
        this.validationController = validationControllerFactory.createForCurrentScope();

        this.validationController.addRenderer(new BootstrapFormRenderer());
    }

    async activate(params: any) {
        this.logger.debug('activate');
        this.id = params.topicId;

        await this.getModel();
        await this.getCourses();
    }

    async getModel() {
        this.logger.debug('getModel');

        await this.api.find('/Topic/GetById', this.id)
            .then(async (data: TopicViewModel) => {
                this.logger.debug('Success getModel: ', data);
                this.model = new TopicViewModel(data);
            }).catch(async (err) => {
                this.logger.debug('Error getModel: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

    async getCourses() {
        this.logger.debug('getCourses');

        await this.api.find('/Course/GetAll')
            .then((data: Array<CourseViewModel>) => {
                this.courses = data;
                this.logger.debug('Success getCourses: ', data);
            }).catch(async (err) => {
                this.logger.debug('Error getCourses: ', await err.json());
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

    async save() {
        this.logger.debug('save');
        this.saveDisabled = true;

        // this.model.courseType = this.courseTypeId; // set enum
        const validation: ControllerValidateResult = await this.validationController.validate();

        if (validation) {
            await this.api.update('/Topic/Update', this.id, this.model)
                .then((data: TopicEditViewModel) => {
                    this.logger.debug('Success save: ' + data);
                    this.saveDisabled = false;
                    this.dialogController.ok(data);
                }).catch(async (err) => {
                    this.saveDisabled = false;
                    this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                });
        }
    }

    cancel() {
        this.dialogController.cancel();
    }
}