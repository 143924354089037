// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { CourseSchoolClassSpecificsViewModel } from './courseschoolclassspecificsview-model';
import { UserViewModel } from './userview-model';


export class CourseSchoolClassSpecifics_ApplicationUserViewModel {
    courseSchoolClassSpecificsId: number = 0;
	courseSchoolClassSpecifics: CourseSchoolClassSpecificsViewModel = null;
	userId: string = null;
	user: UserViewModel = null;
	lastUpdateDate: Date = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        
	}
}