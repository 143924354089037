// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';


export class InfoPanelViewModel {
    studentName: string = null;
	studentSchoolName: string = null;
	studentClassName: string = null;
	studentYearName: string = null;
	studentDivision: string = null;
	studentCourse: string = null;
	selectedClassName: string = null;
	selectedStudentName: string = null;
	selectedCourseName: string = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        
	}
}