import { autoinject } from 'aurelia-dependency-injection';
import { Redirect, NavigationInstruction, Next } from 'aurelia-router';
import { AuthService } from 'aurelia-authentication';

@autoinject
export class RoleStep {

	// the name of the role claim used by .net core identity
	private static readonly roleClaimName: string = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

	constructor(private authService: AuthService)
	{
		this.authService = authService;
	}

	// returns true if user should be able to navigate to this item based on roles
	public UserCanNavigateRole(navigationInstruction: NavigationInstruction): boolean
	{
		let config = navigationInstruction.config;

		if (config.settings == undefined || config.settings.roles == undefined || config.settings.roles.length === 0) {
			// no roles defined for this route
			return true;
		}

		let payload = this.authService.getTokenPayload();

		if (payload == null) {
			// there is no aurelia authentication object, so that must mean where not actually logged in
			// all we can do is just drop out to the next... I think anyway - TODO: check if we can do this better
			return true;
		}

		let userRoles: Array<string> = payload[RoleStep.roleClaimName];
		let pageRoles: Array<string> = config.settings.roles;

		let test: Array<string> = pageRoles.filter(value => -1 !== userRoles.indexOf(value));
		return test.length > 0;
	}

	public run(navigationInstruction: NavigationInstruction, next: Next): Promise<any>
	{
		let userRolesContainsPageRoles = this.UserCanNavigateRole(navigationInstruction);

		if (!userRolesContainsPageRoles) {
			console.warn('user does not have required role to visit this page');
			return next.cancel(new Redirect("home"));
		}

		return next();
	}

	// returns true if the user is in 1 or more roles defined in roleToCheck
	public hasRole(roleToCheck: Array<string>): boolean
	{
		let payload = this.authService.getTokenPayload();

		if (payload == null) {
			// there is no aurelia authentication object, so that must mean where not actually logged in
			// all we can do is just drop out to the next... I think anyway - TODO: check if we can do this better
			return true;
		}

		let userRoles: Array<string> = payload[RoleStep.roleClaimName];
		let test: Array<string> = roleToCheck.filter(value => -1 !== userRoles.indexOf(value));

		return test.length > 0;
	}
}