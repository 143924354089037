import { valueConverter } from 'aurelia-framework';

@valueConverter('submenu')
export class SubMenuValueConverter {
	toView(routerMenuItems: any) {
		var menuItems = [];

		if (routerMenuItems) {
			routerMenuItems.forEach(function (menuItem) {

				if (menuItem.settings.parentMenu) {
					// Submenu children
					var parent = menuItems.find(x => x.config.name == menuItem.settings.parentMenu);
					if (parent) {
						parent.children.push(menuItem);
					}
				} else {
					// Just insert.  It should not be there multiple times or it's a bad route
					menuItems[menuItem] = menuItems[menuItem] || [];
					// Create empty children
					menuItem.children = [];
					menuItems.push(menuItem);
				}
			});
		}

		return menuItems;
	}
}