import { Rest, Endpoint } from 'aurelia-api';
import { autoinject, inject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { DialogController } from 'aurelia-dialog';
import { Helpers } from '../../services/helpers';
import { NotificationCreateViewModel } from './../../models/notificationcreateview-model'
import { CourseViewModel } from '../../models/courseview-model';
import { SchoolViewModel } from '../../models/schoolview-model';

@autoinject
export class NotificationsModalCreate {
	private validationController: ValidationController | null = null;
	private logger: Logger = LogManager.getLogger('NOTIFICATIONMODALCREATE');
    public model: NotificationCreateViewModel = null;
    private saveDisabled: boolean = false;
    private schools: SchoolViewModel[] = [];
    private courses: CourseViewModel[] = [];

	constructor(
		@inject(Endpoint.of('api'))
		private api: Rest,
		private validationControllerFactory: ValidationControllerFactory,
		private dialogController: DialogController) {
			this.validationController = validationControllerFactory.createForCurrentScope();
			this.validationController.addRenderer(new BootstrapFormRenderer());
	}

    async activate() {
        this.logger.debug("activate");
        this.model = new NotificationCreateViewModel();

        await this.getSchools();
        await this.getCourses();
    }

    async getSchools() {
        this.logger.debug('getSchools');

        await this.api.find('/School/GetAll')
            .then((data: Array<SchoolViewModel>) => {
                this.schools = data;
                this.logger.debug('Success getSchools: ', data);
            }).catch(async (err) => {
                this.logger.debug('Error getSchools: ', await err.json());
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

    async getCourses() {
        this.logger.debug('getCourses');

        await this.api.find('/Course/GetAll')
            .then((data: Array<CourseViewModel>) => {
                this.courses = data;
                this.logger.debug('Success getCourses: ', data);
            }).catch(async (err) => {
                this.logger.debug('Error getCourses: ', await err.json());
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

    async save() {
		this.logger.debug('save');
        this.saveDisabled = true;

		const validation: ControllerValidateResult = await this.validationController.validate();

        if (validation.valid !== false) {
			await this.api.create('/Notification/Insert', this.model)
				.then((data: NotificationCreateViewModel) => {
					this.saveDisabled = false;
                    this.logger.debug('Success save: ', data);
					this.dialogController.ok(data);
                }).catch(async (err) => {
                    this.saveDisabled = false;
                    this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                });
		} else {
			this.saveDisabled = false;
		}
	}

	async cancel() {
		await this.dialogController.cancel();
	}
}