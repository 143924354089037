import { inject, autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { Rest, Endpoint } from 'aurelia-api';
import { ValidationControllerFactory, ValidationController, ControllerValidateResult } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { Router } from 'aurelia-router';
import { ResetPasswordViewModel } from '../../models/resetpasswordview-model';
import { Helpers } from '../../services/helpers';

@autoinject
export class ResetPassword {

	private logger: Logger = LogManager.getLogger('RESETPASSWORD');
	private validationController: ValidationController | null = null;
	public model: ResetPasswordViewModel;
	private token: string;

	constructor(
		@inject(Endpoint.of('api'))
		private api: Rest,
		private validationControllerFactory: ValidationControllerFactory,
		private router: Router) {

		this.validationController = validationControllerFactory.createForCurrentScope();
		this.validationController.addRenderer(new BootstrapFormRenderer());
	}

	async activate(params: any) {
		this.logger.debug('activate')

		this.model = new ResetPasswordViewModel();
		this.model.token = params.token;

		if (this.model.token == null) {
			this.router.navigate("")
		}
	}

	async resetpass() {
		this.logger.debug('resetpass');
		const validation: ControllerValidateResult = await this.validationController.validate();

		if (validation.valid) {		
			await this.api.create('/Account/ResetPassword', this.model)
				.then(async (data) => {
					this.logger.debug('Success resetpassword', data);

				// redirect to confirmation page.
				this.router.navigate("reset-password-confirmation");
			}).catch(async (err) => {
				this.logger.debug('Error resetpassword', err);
				await Helpers.ParseValidationError(err, this.validationController, this.model);
			});
		}
	}
}