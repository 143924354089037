import { Logger } from 'aurelia-logging';
import { AuthService } from 'aurelia-authentication';
import { inject, computedFrom, autoinject, LogManager, bindable } from 'aurelia-framework';
import { SharedState } from '../../state/shared-state';
import { Router } from 'aurelia-router';
import { NotificationViewModel } from '../../models/notificationview-model';
import { Endpoint, Rest } from 'aurelia-api';
import { DialogService } from 'aurelia-dialog';

@autoinject
export class NotificationsPanel {
    @bindable studentId: string = null;
    @bindable teacherId: string = null;

    private messages: NotificationViewModel[] | null = null;
    private logger: Logger = LogManager.getLogger('NOTIFICATIONSPANEL');
    private isRequesting: boolean;
    private loadingDone: boolean = false;

    constructor(
        @inject(Endpoint.of('api'))
        private api: Rest,
        private dialogService: DialogService) { }

    public async bind() {
        this.logger.debug('bind');
        if (this.studentId != null) {
            // student fetching messages
            await this.getMessagesForStudent();
        } else if (this.teacherId != null) {
            // teacher fetching messages
            await this.getMessagesForTeacher();
        }

        this.isRequesting = false;
        this.loadingDone = true;
    }

    async getMessagesForStudent() {
        this.isRequesting = true;

        await this.api.find('/Notification/GetByStudentId', this.studentId).then(async (data: Array<NotificationViewModel>) => {
            this.messages = data;
            this.logger.debug('Success: getCurrentUser');
        }).catch(async (error) => {
            this.logger.debug('Error getMessagesForStudent: ', await error.json());
        })

        this.isRequesting = false;
    }

    async getMessagesForTeacher() {
        this.isRequesting = true;

        this.logger.debug('getMessagesForTeacher');

        await this.api.find('/Notification/GetByTeacherId', this.teacherId).then(async (data: Array<NotificationViewModel>) => {
            this.messages = data;
            this.logger.debug('Success: getMessagesForTeacher');
        }).catch(async (error) => {
            this.logger.debug('Error getMessagesForTeacher: ', await error.json());
        })

        this.isRequesting = false;
    }

}