import { DateTime } from 'luxon';
import { ValidationController } from 'aurelia-validation';

export class Helpers {
	constructor() {
	}

	public static mapProperties(source: any, target: any): void {
		Object.keys(target).forEach((key) => {
			if (typeof source[key] !== 'undefined') {
				target[key] = source[key];
			}
		});
	}

	public static isValidDate(value: any): boolean {
		const validDate = DateTime.fromJSDate(value);
		if (validDate.isValid) {
			return validDate.isValid;
		}

		// should be done differently, another check if string includes GMT+
		const index = value.toString().indexOf('GMT+');
		return (index > 0)

	}

	public static querystringify(obj: any, prefix: string = null): string {
		if (!obj) {
			return null;
		}

		prefix = prefix || '';
		let pairs = [];

		//
		// Optionally prefix with a '?' if needed
		//
		if ('string' !== typeof prefix) prefix = '?';

		Object.keys(obj).forEach((key: any) => {
			if (obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) {
				let value = obj[key];

				if (this.isValidDate(value)) {
					value = new Date(value).toISOString();
				}

				// if we're an array, add key before each value
				if (obj[key] instanceof Array) {
					value.forEach(val => {
						pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(val));
					});
				} else {
					pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
				}
			}
		});

		return pairs.length ? prefix + pairs.join('&') : '';
	}

	public static async ParseValidationError(err, controller: ValidationController, object: any) {
		var errorObj = await err.json();
		if (errorObj) {
			Object.keys(errorObj).forEach(function (key) {
				if (key.toLowerCase() == "errors") {
					Object.keys(errorObj[key]).map(function (akey, aindex) {
						var value = errorObj[key][akey];
						const lowercasedProp = akey.charAt(0).toLowerCase() + akey.slice(1);
						controller.addError(value, object, lowercasedProp);
					})
				}
				else if (key.toLowerCase() == "0") {
					for (var obj = 0; obj < Object.keys(errorObj).length; obj++) {
						var akey = Object.keys(errorObj)[obj];
						const lowercasedProp = akey.charAt(0).toLowerCase() + key.slice(1);
						controller.addError(errorObj[akey], object, lowercasedProp);
					}
				}
			});
		}
	}

	public static GenerateRandomPincode(): string {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;

		// 8 characters long
		for (var i = 0; i < 8; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	public static sortModel(_class: Object[], field: string, ascending: boolean) {
		const splitFieldNames = field.split('.', 2);

		if (splitFieldNames.length > 1) {
			const name0 = splitFieldNames[0],
				name1 = splitFieldNames[1];

			if (ascending !== false) {
				_class.sort(function (a, b) {
					const aValue = a[name0][name1],
						bValue = b[name0][name1];

					if (typeof aValue === 'string' && typeof bValue === 'string') {
						if (aValue.toLowerCase() < bValue.toLowerCase()) { return -1; }
						if (aValue.toLowerCase() > bValue.toLowerCase()) { return 1; }
					} else {
						if (aValue < bValue) { return -1; }
						if (aValue > bValue) { return 1; }
					}

					return 0;
				});
			} else {
				_class.sort(function (a, b) {
					const aValue = a[name0][name1],
						bValue = b[name0][name1];

					if (typeof aValue === 'string' && typeof bValue === 'string') {
						if (aValue.toLowerCase() > bValue.toLowerCase()) { return -1; }
						if (aValue.toLowerCase() < bValue.toLowerCase()) { return 1; }
					} else {
						if (aValue > bValue) { return -1; }
						if (aValue < bValue) { return 1; }
					}

					return 0;
				});
			}
		} else {
			if (ascending !== false) {
				_class.sort(function (a, b) {
					const aValue = a[field],
						bValue = b[field];

					if (typeof aValue === 'string' && typeof bValue === 'string') {
						if (aValue.toLowerCase() < bValue.toLowerCase()) { return -1; }
						if (aValue.toLowerCase() > bValue.toLowerCase()) { return 1; }
					} else {
						if (aValue < bValue) { return -1; }
						if (aValue > bValue) { return 1; }
					}

					return 0;
				});
			} else {
				_class.sort(function (a, b) {
					const aValue = a[field],
						bValue = b[field];

					if (typeof aValue === 'string' && typeof bValue === 'string') {
						if (aValue.toLowerCase() > bValue.toLowerCase()) { return -1; }
						if (aValue.toLowerCase() < bValue.toLowerCase()) { return 1; }
					} else {
						if (aValue > bValue) { return -1; }
						if (aValue < bValue) { return 1; }
					}

					return 0;
				});
			}
		}
	}

}
