import { inject, autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';
import { Endpoint, Rest } from 'aurelia-api';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { Helpers } from '../../services/helpers';
import { UserViewModel } from '../../models/userview-model';

@autoinject
export class StudentModalDelete {
    private id: string;
    public model: UserViewModel;
    private logger: Logger = LogManager.getLogger('STUDENTSMODALDELETE');
    private validationController: ValidationController | null = null;
    private saveDisabled: boolean = false;

    constructor(
        @inject(Endpoint.of('api')) private api: Rest,
        private dialog: DialogController,
        private validationControllerFactory: ValidationControllerFactory) {

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.addRenderer(new BootstrapFormRenderer());
    }

    async activate(params: any) {
        this.logger.debug("activate");
        this.id = params.studentId;

        await this.api.find('/User/GetById', this.id)
            .then(async (data: UserViewModel[]) => {
                this.logger.debug('Success activate: ', data);
                this.model = new UserViewModel(data);
            }).catch(async (err) => {
                this.logger.debug('Error activate: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

    async cancel() {
        this.logger.debug('cancel');
        await this.dialog.cancel();
    }

    async save() {
        this.logger.debug('save');
        this.saveDisabled = true;

        const validation: ControllerValidateResult = await this.validationController.validate();

        if (validation.valid) {
            await this.api.destroy('/User/DeleteById', this.id)
                .then(async (data: any) => {
                    this.logger.debug('Success save: ', data);
                    this.saveDisabled = false;
                    //close modal
                    await this.dialog.ok(data);
                }).catch(async (err) => {
                    this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                    this.saveDisabled = false;
                });
        } else {
            this.saveDisabled = false;
        }
    }
}