import { autoinject, inject, LogManager, bindable } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { DialogController } from 'aurelia-dialog';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { ValidationController, ValidationControllerFactory, ControllerValidateResult } from 'aurelia-validation';
import { Rest, Endpoint } from 'aurelia-api';
import { SchoolViewModel } from '../../models/schoolview-model';
import { Helpers } from '../../services/helpers';
import { UserEditViewModel } from '../../models/usereditview-model';
import { UserViewModel } from '../../models/userview-model';

@autoinject
export class TeacherModalUpdate {
    @bindable id: number = 0;
    model: UserEditViewModel;
    schools: SchoolViewModel[] = [];
    logger: Logger = LogManager.getLogger('TEACHERMODALUPDATE');
    validationController: ValidationController;
    private saveDisabled: boolean = false;

    constructor(
        @inject(Endpoint.of('api'))
        private api: Rest,
        private dialog: DialogController,
        private validationControllerFactory: ValidationControllerFactory) {

        this.validationController = this.validationControllerFactory.createForCurrentScope();
        this.validationController.addRenderer(new BootstrapFormRenderer());
    }

    async activate(params: any) {
        this.logger.debug('activate');

        this.id = params.teacherId;
        await this.getModel();
        await this.getSchools();
    }

    async getModel() {
        this.logger.debug('getModel');

        await this.api.findOne('/User/GetById', this.id)
            .then((data: UserViewModel) => {
				this.model = new UserEditViewModel(data);
                this.logger.debug('Success getModel: ', data);
            }).catch(async (err) => {
                this.logger.debug('Error getModel: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

    async getSchools() {
        this.logger.debug('getSchools');

        await this.api.find('/School/GetAll')
            .then((data: Array<SchoolViewModel>) => {
                this.schools = data;
                this.logger.debug('Success getSchools');
            }).catch(async (err) => {
                this.logger.debug('Error getSchools: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

	async convertToStudent() {
		await this.api.update('/User/ConvertToStudent', this.id)
			.then((data: any) => {
				this.logger.debug('Success converting teacher');
				// refresh
				this.dialog.ok();
			}).catch(async (err) => {
				this.logger.debug('Error converting: ', err);
				await Helpers.ParseValidationError(err, this.validationController, this.model);
				this.saveDisabled = false;
			})
	}

    async save() {
        this.logger.debug('save');

        this.saveDisabled = true;
        const validation: ControllerValidateResult = await this.validationController.validate();
        if (validation.valid) {
            await this.api.update('/User/Update', this.id, this.model)
                .then((data: UserEditViewModel) => {
                    this.logger.debug('Success save');
                    this.saveDisabled = false;
                    this.dialog.ok(data);
                }).catch(async (err) => {
                    this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                    this.saveDisabled = false;
                });
        }
    }

    async cancel() {
        this.logger.debug('cancel');
        this.dialog.cancel();
    }
}