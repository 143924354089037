
// Auto generated by ControleTool.ModelClassGenerator

export class Constants {
    public static readonly policyRequireAdminOrTeacher: string = "RequireAdminOrTeacherRole";
	public static readonly roleAdministrator: string = "Administrator";
	public static readonly roleTeacher: string = "Teacher";
	public static readonly roleStudent: string = "Student";
	public static readonly noContextUser: string = "Unknown";
	public static readonly corsPolicyAllowAll: string = "CorsAllowAll";
	public static readonly eolAnswerFetchError: string = "EOLANSWERFETCHERROR";
	public static readonly eolTableNotFound: string = "Table {0} not found in database!";
	public static readonly lastSyncCompleteCourseDate: string = "LASTSYNCCOMPLETECOURSEDATE";
	public static readonly selectedStudent: string = "SelectedStudent";
	public static readonly selectedSchoolClass: string = "SelectedSchoolClass";
	public static readonly orientationSwitched: string = "OrientationSwitched";
	public static readonly sessionGuid: string = "SessionGuid";
	public static readonly refreshStatus: string = "RefreshStatus";
	
}
