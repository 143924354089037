// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';


export class ForgotPasswordViewModel {
    email: string = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('email').displayName('email').required()
			.on(this);
	}
}