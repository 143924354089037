import { autoinject, customElement } from 'aurelia-framework';
import { AuthService } from "aurelia-authentication";
import { SharedState } from "./../../state/shared-state";
import { Router } from 'aurelia-router';

@autoinject
@customElement('user-block')
export default class {
    protected authProfileUrl: string | null = null;

	constructor(
		private router: Router,
		private auth: AuthService,
		protected sharedState: SharedState) {
		this.auth = auth;
    }

	public async attached() {
		this.authProfileUrl = "/profile";
	}

	public logout() {
		this.auth.logout();
		this.router.navigate("/");
    }
}