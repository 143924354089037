import "isomorphic-fetch";
import { Aurelia, PLATFORM } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import "bootstrap";
import "font-awesome/css/font-awesome.css";
import { SharedState } from "./app/state/shared-state";
import { AppSettingsViewModel } from "./app/models/appsettingsview-model";

declare const IS_DEV_BUILD: boolean; // The value is supplied by Webpack during the build

export async function configure(aurelia: Aurelia) {

	let state: SharedState = aurelia.container.get(SharedState);
	let appConfig: AppSettingsViewModel = await state.getAppSettings();

	aurelia.use
		.standardConfiguration()
		.feature(PLATFORM.moduleName('app/resources/index'))
		.globalResources(PLATFORM.moduleName('aurelia-authentication/authFilterValueConverter'))
		.plugin(PLATFORM.moduleName('aurelia-api'), configure => {
			configure
				.registerEndpoint('auth', appConfig.apiPath)
				.registerEndpoint('api', appConfig.apiPath)
				.setDefaultEndpoint('auth');
		})
		.plugin(PLATFORM.moduleName('aurelia-dialog'), config => {
			config.useDefaults();
			config.settings.lock = true;
			config.settings.startingZIndex = 1050;
			config.settings.centerHorizontalOnly = true;
		})
		.plugin(PLATFORM.moduleName('aurelia-toolbelt/components/bootstrap'))
		.plugin(PLATFORM.moduleName('aurelia-validation'))
		.plugin(PLATFORM.moduleName('aurelia-authentication'), {
			// This is the name of the endpoint used for any requests made in relation to authentication (login, logout, etc.). An empty string selects the default endpoint of aurelia-api.
			endpoint: 'auth',
			// When authenticated, these endpoints will have the token added to the header of any requests (for authorization). Accepts an array of endpoint names. An empty string selects the default endpoint of aurelia-api.
			configureEndpoints: ['auth', 'api'],
			// Refresh Token Options
			// =====================

			// The API endpoint to which refreshToken requests are sent. null = loginUrl
			refreshTokenUrl: '/auth/refreshtoken',

			// Option to turn refresh tokens On/Off
			useRefreshToken: true,
			// The option to enable/disable the automatic refresh of Auth tokens using Refresh Tokens
			autoUpdateToken: true,
			// Oauth Client Id
			clientId: false,
			// The the property from which to get the refresh token after a successful token refresh
			refreshTokenProp: 'refresh_token',
			// The proprety name used to send the existing token when refreshing `{ "refreshTokenSubmitProp": '...' }`
			refreshTokenSubmitProp: 'refresh_token',
			// store token in session storage, so user implicitly logs out when closing tab without clicking logout
			storage: 'sessionStorage',
		})

	if (IS_DEV_BUILD) {
		aurelia.use.developmentLogging();
	}

	aurelia.start().then(() => aurelia.setRoot("app/components/app/app"));
}
