import { autoinject, inject, bindable, LogManager } from 'aurelia-framework';
import { Endpoint, Rest } from 'aurelia-api';
import { Logger } from 'aurelia-logging';
import { DialogController } from 'aurelia-dialog';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { SchoolYearViewModel } from './../../models/schoolyearview-model';
import { SchoolYearEditViewModel } from './../../models/schoolyeareditview-model';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { SchoolViewModel } from '../../models/schoolview-model';
import { Helpers } from '../../services/helpers';

@autoinject
export class SchoolYearsModalUpdate {
	private id: number;
	public model: SchoolYearEditViewModel;
	public schools: Array<SchoolViewModel> = [];
	private logger: Logger = LogManager.getLogger('SCHOOLYEARSMODALUPDATE');
	private validationController: ValidationController | null = null;
	private saveDisabled: boolean = false;

	constructor(
		@inject(Endpoint.of('api')) private api: Rest,
		private diaLog: DialogController,
		private validationControllerFactory: ValidationControllerFactory) {

		this.validationController = validationControllerFactory.createForCurrentScope();
		this.validationController.addRenderer(new BootstrapFormRenderer());
	}

    async activate(params: any) {
        this.logger.debug('activate');

        this.id = params.yearId;
		await this.getModel();
		await this.getSchools();
	}

    async getModel() {
        this.logger.debug('getModel');

		await this.api.find('/SchoolYear/GetById', this.id)
			.then(async (data: SchoolYearViewModel) => {
				this.logger.debug('Success getModel: ', data);
				this.model = new SchoolYearViewModel(data);
			}).catch(async (err) => {
				this.logger.debug('Error getModel: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
			});
	}

    async getSchools() {
        this.logger.debug('getSchools');

		await this.api.find('/School/GetAll')
			.then((data: Array<SchoolViewModel>) => {
				this.schools = data;
				this.logger.debug('Success getSchools');
            }).catch(async (err) => {
                this.logger.debug('Error getSchools: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
	}

	async cancel() {
		this.logger.debug('cancel');
		await this.diaLog.cancel();
	}

    async save() {
        this.logger.debug('save');
		this.saveDisabled = true;

		const validation: ControllerValidateResult = await this.validationController.validate();

		if (validation.valid) {
			await this.api.update('/SchoolYear/Update', this.model.id, this.model)
				.then(async (data: SchoolYearViewModel) => {
					this.logger.debug('Success save: ', data);
					this.saveDisabled = false;
					//close modal
					await this.diaLog.ok(data);
				}).catch(async (err) => {
					this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
					this.saveDisabled = false;
				});
		} else {
			this.saveDisabled = false;
		}
	}
}