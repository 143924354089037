import { Endpoint, Rest } from 'aurelia-api';
import { DialogService } from 'aurelia-dialog';
import { autoinject, inject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { HttpError } from '../../models/general';
import { Helpers } from '../../services/helpers';
import { NotificationViewModel } from './../../models/notificationview-model';
import { NotificationsModalCreate } from './notifications-modal-create';
import { NotificationsModalDelete } from './notifications-modal-delete';
import { NotificationsModalUpdate } from './notifications-modal-update';

@autoinject
export class Notifications {
	private logger: Logger = LogManager.getLogger('NOTIFICATIONS');
	private isRequesting: boolean = true;
	private serverError: HttpError | null = null;
	private model: Array<NotificationViewModel> | null = null;
	private search: string | null = null;
	private selectedField: string = 'description';
	private ascending: boolean = true;
	private showPagination: boolean = true;
	private paginationSelectedPage: number = 1;
	private paginationTake: number = 15;
	private paginationPages: number = 0;

	constructor(
		@inject(Endpoint.of('api'))
		private api: Rest,
		private dialogService: DialogService) { }

    async bind() {
		await this.getModel();
	}

    async getModel() {
        this.logger.debug('getModel');
        this.isRequesting = true;
        await this.api.find('/Notification/GetAll')
            .then((data: Array<NotificationViewModel>) => {
                this.model = data;
                Helpers.sortModel(this.model, this.selectedField, this.ascending);

                this.paginationPages = Math.ceil(this.model.length / this.paginationTake);
                this.showPagination = this.paginationPages > 1;
                this.logger.debug('Success: getModel');
			}).catch(async (err) => {
				var errorObj = await err.json();
				this.logger.debug('Error getModel: ', errorObj);

				// set errorMessage
				this.serverError = errorObj;
			});

        this.isRequesting = false;
	}

	sortModel(_class: Object[], field: string) {
		this.showPagination = false;
		this.selectedField = field;
		this.ascending === false ? this.ascending = true : this.ascending = false;
		this.paginationSelectedPage = 1;
		Helpers.sortModel(_class, this.selectedField, this.ascending);

		// make pagination control refresh because everyting in it is binding once
		setTimeout(() => {
			this.showPagination = this.paginationPages > 1;
		}, 100);
	}

	pageChanged($event, selectedPageNumber) {
		this.paginationSelectedPage = selectedPageNumber;
	}

	async newItem() {
		this.logger.debug('newItem');
		this.dialogService.open({ viewModel: NotificationsModalCreate }).whenClosed(response => this.onClose(response));
	}

	async edit(id) {
		this.logger.debug('edit');
		this.dialogService.open({ viewModel: NotificationsModalUpdate, model: { notificationId: id }}).whenClosed(response => this.onClose(response));
	}

	async delete(id) {
		this.logger.debug('delete');
		this.dialogService.open({ viewModel: NotificationsModalDelete, model: { notificationId: id }}).whenClosed(response => this.onClose(response));
	}

	async onClose(response) {
		if (!response.wasCancelled) {
			await this.getModel();
			return response.output;
		} else {
			return null;
		}
	}
}