// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { SchoolClassViewModel } from './schoolclassview-model';
import { UserViewModel } from './userview-model';
import { StudentStoredActivityViewModel } from './studentstoredactivityview-model';


export class ExportClassTimeViewModel {
    selectedClass: SchoolClassViewModel = null;
	students: UserViewModel[] = [];
	studentStoredActivity: StudentStoredActivityViewModel[] = [];
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        
	}
}