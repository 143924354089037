// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { TopicViewModel } from './topicview-model';

import { CheckType } from './enums';

export class AssignmentEditViewModel {
    id: number = 0;
	order: number = 0;
	description: string = null;
	checkType: CheckType = null;
	topicId: number = 0;
	topic: TopicViewModel = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        
	}
}