// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';


export class DivisionViewModel {
    code: string = null;
	description: string = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        
	}
}