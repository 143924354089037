import { inject, autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';
import { Endpoint, Rest } from 'aurelia-api';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { SchoolEditViewModel } from '../../models/schooleditview-model';
import { SchoolViewModel } from '../../models/schoolview-model';
import { Helpers } from '../../services/helpers';

@autoinject
export class SchoolsModalUpdate {
	private id: number;
	public model: SchoolEditViewModel;
	private logger: Logger = LogManager.getLogger('SCHOOLSMODALUPDATE');
	private validationController: ValidationController | null = null;
	private saveDisabled: boolean = false;

	constructor(
		@inject(Endpoint.of('api')) private api: Rest,
		private dialog: DialogController,
		private validationControllerFactory: ValidationControllerFactory) {

		this.validationController = validationControllerFactory.createForCurrentScope();
		this.validationController.addRenderer(new BootstrapFormRenderer());
	}

    async activate(params: any) {
        this.logger.debug("activate");
		this.id = params.schoolId;
		await this.getModel();
	}

    async getModel() {
        this.logger.debug('getModel');

		await this.api.find('/School/GetById', this.id)
			.then(async (data: SchoolViewModel) => {
				this.logger.debug('Success getModel: ', data);
				this.model = new SchoolViewModel(data);
			}).catch(async (err) => {
				this.logger.debug('Error getModel: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
			});
	}

	async cancel() {
		this.logger.debug('cancel');
		await this.dialog.cancel();
	}

    async save() {
        this.logger.debug('save');
		this.saveDisabled = true;

		const validation: ControllerValidateResult = await this.validationController.validate();

		if (validation.valid) {
			await this.api.update('/School/Update', this.model.id, this.model)
				.then(async (data: SchoolViewModel) => {
					this.logger.debug('Success save: ', data);
					this.saveDisabled = false;
					//close modal
					await this.dialog.ok(data);
				}).catch(async (err) => {
					this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
					this.saveDisabled = false;
				});
		} else {
			this.saveDisabled = false;
		}
	}
}