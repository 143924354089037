// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';


export class ApplicationUserViewModel {
    id: string = null;
	userName: string = null;
	email: string = null;
	phoneNumber: string = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('userName').displayName('Gebruikersnaam').required()
			.ensure('email').displayName('E-mail').required()
			.on(this);
	}
}