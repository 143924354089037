import { Constants } from "../models/constants";

export const NavConfig = [{
	route: ["", "home"],
	name: "home",
	settings: { icon: "la la-home" },
	moduleId: "../home/home",
	nav: true,
	title: "Home"
}, {
	route: "forgot-password",
	name: "forgotpassword",
	settings: {
	},
	moduleId: "../password/forgot-password",
	nav: false,
	auth: false,
	title: "Wachtwoord vergeten"
}, {
	route: "forgot-password-confirmation",
	name: "forgotpasswordconfirmation",
	settings: {
	},
	moduleId: "../password/forgot-password-confirmation",
	nav: false,
	auth: false,
	title: "Wachtwoord vergeten bevestiging"
}, {
	route: "reset-password",
	name: "resetpassword",
	settings: {
	},
	moduleId: "../password/reset-password",
	nav: false,
	auth: false,
	title: "Wachtwoord herstellen"
}, {
	route: "reset-password-confirmation",
	name: "resetpasswordconfirmation",
	settings: {
	},
	moduleId: "../password/reset-password-confirmation",
	nav: false,
	auth: false,
	title: "Wachtwoord herstellen bevestiging"
}, {
	route: "Cursusbeheer",
	name: "coursemanagement",
	settings: {
		icon: "la la-columns",
		roles: [Constants.roleAdministrator]
	},
	moduleId: "../masterdata/masterdata",
	nav: true,
	auth: true,
	title: "Cursusbeheer"
}, {
	route: "Snel invoeren cursus",
	name: "coursesquickentry",
	settings: {
		parentMenu: 'coursemanagement',
		icon: "la la-tachometer",
		roles: [Constants.roleAdministrator]
	},
	moduleId: "../quickentry/courses-quick-entry",
	nav: true,
	auth: true,
	title: "Snel invoeren"
}, {
	route: "Cursussen",
	name: "courses",
	settings: {
		parentMenu: 'coursemanagement',
		icon: "la la-trophy",
		roles: [Constants.roleAdministrator]
	},
	moduleId: "../courses/courses",
	nav: true,
	auth: true,
	title: "Cursussen"
}, {
	route: "Hoofdstukken",
	name: "topics",
	settings: {
		parentMenu: 'coursemanagement',
		icon: "la la-book",
		roles: [Constants.roleAdministrator]
	},
	moduleId: "../topics/topics",
	nav: true,
	auth: true,
	title: "Hoofdstukken"
}, {
	route: "Gebruikers",
	name: "users",
	settings: {
		icon: "la la-users",
		roles: [Constants.roleAdministrator]
	},
	moduleId: "../users/users",
	nav: true,
	auth: true,
	title: "Gebruikers"
}, {
	route: "Stambestanden",
	name: "masterdata",
	settings: {
		icon: "la la-table",
		roles: [Constants.roleAdministrator]
	},
	moduleId: "../masterdata/masterdata",
	nav: true,
	auth: true,
	title: "Stamgegevens"
}, {
	route: "Snel invoeren scholen",
	name: "schoolsquickentry",
	settings: {
		parentMenu: 'masterdata',
		icon: "la la-tachometer",
		roles: [Constants.roleAdministrator]
	},
	moduleId: "../quickentry/schools-quick-entry",
	nav: true,
	auth: true,
	title: "Snel invoeren"
}, {
	route: "Docenten",
	name: "teachers",
	settings: {
		parentMenu: 'masterdata',
		icon: "la la-user",
		roles: [Constants.roleAdministrator]
	},
	moduleId: "../teachers/teachers",
	nav: true,
	auth: true,
	title: "Docenten"
}, {
	route: "Studenten",
	name: "students",
	settings: {
		parentMenu: 'masterdata',
		icon: "la la-graduation-cap",
		roles: [Constants.roleAdministrator]
	},
	moduleId: "../students/students",
	nav: true,
	auth: true,
	title: "Studenten"
}, {
	route: "Notificaties",
	name: "notifications",
	settings: {
		icon: "la la-bell",
		roles: [Constants.roleAdministrator]
	},
	moduleId: "../notifications/notifications",
	nav: true,
	auth: true,
	title: "Notificaties"
}, {
	route: "Cursus",
	name: "studentcourse",
	settings: {
		icon: "la la-trophy",
		roles: [Constants.roleStudent]
	},
	moduleId: "../studentcourse/studentcourse",
	nav: true,
	auth: true,
	title: "Cursus"
}, {
	route: "profile",
	name: "profile",
	settings: {
		icon: "la la-user",
		roles: [Constants.roleAdministrator, Constants.roleTeacher, Constants.roleStudent]
	},
	moduleId: "../profile/profile",
	nav: true,
	auth: true,
	title: "Mijn gegevens"
}];