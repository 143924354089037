import { autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';

@autoinject
export class ResetPasswordConfirmation {

	private logger: Logger = LogManager.getLogger('RESETPASSWORDCONFIRMATION');

	async activate() {
		this.logger.debug("activate")
	}
}