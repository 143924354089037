// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { CourseViewModel } from './courseview-model';


export class TopicCreateViewModel {
    id: number = 0;
	order: number = 0;
	description: string = null;
	courseId: number = 0;
	course: CourseViewModel = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('description').displayName('Omschrijving').required()
			.ensure('courseId').displayName('Cursus').required()
			.on(this);
	}
}