import { inject, autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';
import { Endpoint, Rest } from 'aurelia-api';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
//import { GeneralUpdateResponseModel, ChangePasswordModel, ProfileIndexModel } from '../../models/generated';
import { HttpError, ErrorResponseModel } from '../../models/general';
import { UserViewModel } from '../../models/userview-model';
import { ChangePasswordViewModel } from '../../models/changepasswordview-model';
import { Helpers } from '../../services/helpers';

@autoinject
export class ChangePasswordDialog {
	public changePassword: ChangePasswordViewModel;
	private logger: Logger = LogManager.getLogger('CHANGEPASSWORDMODAL');
	private validationController: ValidationController | null = null;
	private saveDisabled: boolean = false;
	private isRequesting: boolean = false;
	private model: UserViewModel;

	constructor(
		@inject(Endpoint.of('api')) private apiEndpoint: Rest,
		private dlgController: DialogController,
		private validationControllerFactory: ValidationControllerFactory) {

		this.validationController = validationControllerFactory.createForCurrentScope();
		this.validationController.addRenderer(new BootstrapFormRenderer());
	}

	async activate() {
		this.logger.debug("activate");
		this.isRequesting = true;

		await this.apiEndpoint.find('/User/GetCurrentUser').then(async (data: UserViewModel) => {
			this.model = new UserViewModel(data);
			this.changePassword = new ChangePasswordViewModel();
			this.changePassword.id = this.model.id;

			this.isRequesting = false;
		});
	}

	async cancel() {
		this.logger.debug('cancel');
		await this.dlgController.cancel();
	}

	async save() {
		this.logger.debug('save');
		this.saveDisabled = true;

		const validation: ControllerValidateResult = await this.validationController.validate();
		if (validation.valid) {
			await this.apiEndpoint.request('PUT', '/Account/ChangePassword', this.changePassword)
				.then(async (data) => {
					this.logger.debug('Success save', data);
					this.saveDisabled = false;

					//close modal
					await this.dlgController.ok(data);
				}).catch(async (err: any) => {
					this.logger.debug('Error save: ', err);
					await Helpers.ParseValidationError(err, this.validationController, this.model);
				});

			this.saveDisabled = false;
		}
	}
}