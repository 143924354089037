// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';


export class ChangePasswordViewModel {
    id: string = null;
	oldPassword: string = null;
	newPassword: string = null;
	confirmPassword: string = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('id').displayName('id').required()
			.ensure('oldPassword').displayName('Huidige wachtwoord').required().minLength(6).maxLength(100)
			.ensure('newPassword').displayName('Nieuwe wachtwoord').required().minLength(6).maxLength(100)
			.ensure('confirmPassword').displayName('Bevestig wachtwoord').required().satisfiesRule('matchesProperty', 'NewPassword')
			.on(this);
	}
}