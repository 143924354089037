// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { CourseIndexViewModel } from './courseindexview-model';
import { AssignmentIndexViewModel } from './assignmentindexview-model';
import { CourseSchoolClassSpecificsViewModel } from './courseschoolclassspecificsview-model';


export class TopicIndexViewModel {
    id: number = 0;
	order: number = 0;
	description: string = null;
	courseId: number = 0;
	course: CourseIndexViewModel = null;
	assignments: AssignmentIndexViewModel[] = [];
	totalImportance: number = 0;
	totalScored: number = 0;
	specifics: CourseSchoolClassSpecificsViewModel[] = [];
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('description').displayName('Omschrijving').required()
			.ensure('courseId').displayName('Cursus').required()
			.on(this);
	}
}