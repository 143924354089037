// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { UserViewModel } from './userview-model';
import { CourseIndexViewModel } from './courseindexview-model';
import { CourseSchoolClassSpecifics_ApplicationUserViewModel } from './courseschoolclassspecifics_applicationuserview-model';


export class PortalCourseViewModel {
    callingPortal: string = null;
	user: UserViewModel = null;
	course: CourseIndexViewModel = null;
	courseSpecificsPerUser: CourseSchoolClassSpecifics_ApplicationUserViewModel[] = [];
	lastCompleteSyncDate: Date = null;
	errorMessage: string = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        
	}
}