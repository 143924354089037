// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { CourseViewModel } from './courseview-model';
import { SchoolYearViewModel } from './schoolyearview-model';


export class SchoolClassViewModel {
    id: number = 0;
	name: string = null;
	courseId: number = 0;
	coursePinCode: string = null;
	course: CourseViewModel = null;
	schoolYearId: number = 0;
	lastCompleteSyncDate: Date = null;
	schoolYear: SchoolYearViewModel = null;
	lastCompleteSyncDateView: Date = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('name').displayName('Naam').required()
			.ensure('courseId').displayName('Cursus').required()
			.ensure('coursePinCode').displayName('Pincode').required()
			.ensure('schoolYearId').displayName('Schooljaar').required()
			.on(this);
	}
}