import { inject, autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';
import { Endpoint, Rest } from 'aurelia-api';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { SchoolYearViewModel } from '../../models/schoolyearview-model';
import { Helpers } from '../../services/helpers';

@autoinject
export class SchoolYearsModalDelete {
	private id: number;
	public model: SchoolYearViewModel;
	private logger: Logger = LogManager.getLogger('SCHOOLYEARSMODALDELETE');
	private validationController: ValidationController | null = null;
	private saveDisabled: boolean = false;

	constructor(
		@inject(Endpoint.of('api')) private api: Rest,
		private dialog: DialogController,
		private validationControllerFactory: ValidationControllerFactory) {

		this.validationController = validationControllerFactory.createForCurrentScope();
		this.validationController.addRenderer(new BootstrapFormRenderer());
	}

	async activate(params: any) {
		this.logger.debug("activate");
		this.id = params.yearId;

		await this.api.find('/SchoolYear/GetById', this.id)
			.then(async (data: SchoolYearViewModel[]) => {
				this.logger.debug('Success activate: ', data);
                this.model = new SchoolYearViewModel(data);
			}).catch(async (err) => {
				this.logger.debug('Error activate: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
			});
	}

	async cancel() {
		this.logger.debug('cancel');
		await this.dialog.cancel();
	}

	async save() {
		this.logger.debug('save');
		this.saveDisabled = true;

		const validation: ControllerValidateResult = await this.validationController.validate();

		if (validation.valid) {
			await this.api.destroy('/SchoolYear/DeleteById', this.id)
				.then(async (data: any) => {
					this.logger.debug('Success save: ', data);
					this.saveDisabled = false;
					//close modal
					await this.dialog.ok(data);
				}).catch(async (err) => {
					this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                    this.saveDisabled = false;
				});
		} else {
			this.saveDisabled = false;
		}
	}
}
