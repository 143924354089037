import { Endpoint, Rest } from 'aurelia-api';
import { DialogController } from 'aurelia-dialog';
import { autoinject, inject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { SchoolViewModel } from '../../models/schoolview-model';
import { TeacherCreateViewModel } from '../../models/teachercreateview-model';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { Helpers } from '../../services/helpers';

@autoinject
export class TeacherModalCreate {
    private logger: Logger = LogManager.getLogger('TEACHERMODALCREATE');
    private validationController: ValidationController;
    private model: TeacherCreateViewModel;
    private schools: SchoolViewModel[] = [];
    private saveDisabled: boolean = false;

    constructor(
        @inject(Endpoint.of('api'))
        private api: Rest,
        private dialog: DialogController,
        private validationControllerFactory: ValidationControllerFactory) {

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.addRenderer(new BootstrapFormRenderer());

        this.getModel();
    }

    async getModel() {
        this.logger.debug('getModel');
        this.model = new TeacherCreateViewModel();
    }

    async activate() {
        this.logger.debug('activate');
        await this.getSchools();
    }

    async getSchools() {
        this.logger.debug('getSchools');

        await this.api.find('/School/GetAll')
            .then((data: Array<SchoolViewModel>) => {
                this.schools = data;
                this.logger.debug('Success getSchools');
            }).catch(async (err) => {
				this.logger.debug('Error getSchools: ', err);
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

    cancel() {
        this.logger.debug('cancel');
        this.dialog.cancel();
    }

    async save() {
        this.logger.debug('save');
        this.saveDisabled = true;

        const validation: ControllerValidateResult = await this.validationController.validate();

        if (validation.valid) {
            await this.api.post('/Teacher/Insert', this.model)
                .then(async (data: any) => {
                    this.logger.debug('Success save: ', data);
                    this.saveDisabled = false;
					// close dialog
                    await this.dialog.ok(data);
                }).catch(async (err) => {
					this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                    this.saveDisabled = false;
                });
        } else {
            this.saveDisabled = false;
        }
    }
}