import { autoinject } from 'aurelia-dependency-injection';
import { AuthService } from 'aurelia-authentication';
import { computedFrom } from 'aurelia-binding';
import { RoleStep } from './RoleStep';

@autoinject
export class RoleAuthenticationStep
{
	static currentRole: string | null = null;

	constructor(private authService: AuthService, private roleStep: RoleStep)
	{
		this.authService = authService;
		this.roleStep = roleStep;
	}

	@computedFrom('authService.authenticated')
	get isAuthenticated() {
		let roleAccepted: boolean = true;

		// check if we need to check roles, else return authService.authenticated
		if (RoleAuthenticationStep.currentRole != null) {
			roleAccepted = this.roleStep.hasRole([RoleAuthenticationStep.currentRole]);
		}

		// log out if login wasn't successfull
		var loggedInSuccess: boolean = this.authService.authenticated && roleAccepted;
		if (!loggedInSuccess && this.authService.authenticated) {
			this.authService.logout();
		}

		return loggedInSuccess;
	}

}