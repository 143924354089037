// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { SchoolYearIndexViewModel } from './schoolyearindexview-model';
import { UserViewModel } from './userview-model';


export class SchoolIndexViewModel {
    id: number = 0;
	name: string = null;
	schoolYears: SchoolYearIndexViewModel[] = [];
	teachers: UserViewModel[] = [];
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('name').displayName('Naam').required().maxLength(128)
			.on(this);
	}
}