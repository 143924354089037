import { Logger } from 'aurelia-logging';
import { AuthService } from 'aurelia-authentication';
import { bindable, inject, computedFrom, autoinject, LogManager } from 'aurelia-framework';
import { SharedState } from '../../state/shared-state';
import { Router } from 'aurelia-router';
import { Helpers } from '../../services/helpers';

@autoinject
export class LoginPanel {
	private logger: Logger = LogManager.getLogger('LOGINPANEL');

	constructor(private authService: AuthService, private commonState: SharedState, private router: Router) {
		this.authService = authService;
		this.commonState = commonState;
		this.router = router;
	};

	email: string = '';
	password: string = '';
	errorMessage: string = null;
	hasError: boolean = false;
	isRequesting: boolean = false;

	// use authService.login(credentialsObject) to login to your auth server
	// note: we have a custom 'redirect to target' authenticationstep defined in SaveNavAuthenticateStep (added in app.ts)
	// authService.authenticated holds the current status
	// authService.getPayload() gives you the current payload object (for jwt)
	login() {
		this.isRequesting = true;

		var redirectUri = '#/';

		if (this.commonState.postLoginNavInstr) {
			this.logger.debug('Found redirect route', this.commonState.postLoginNavInstr);

			// note: this only works for router params, otherwise you should use the queryParams property
			redirectUri = this.router.generate(this.commonState.postLoginNavInstr.config.name,
				this.commonState.postLoginNavInstr.params,
				{ replace: true });

			// also add the querystring
			if (this.commonState.postLoginNavInstr.queryString !== '' && this.commonState.postLoginNavInstr.queryString !== null) {
				redirectUri += '/?' + this.commonState.postLoginNavInstr.queryString;
			}
		}

		return this.authService.login(this.email, this.password, {}, redirectUri)
			.then(response => {
				this.logger.debug("success logged " + response);
				this.isRequesting = false;
			}).catch(async (err) => {
				this.hasError = true;
				var errorObj = await err.json();
				this.logger.debug('Error login: ', errorObj);

				// set errorMessage
				this.errorMessage = errorObj;
				this.isRequesting = false;
			});
	};

	// use authService.logout to delete stored tokens
	// if you are using JWTs, authService.logout() will be called automatically,
	// when the token expires. The expiredRedirect setting in your authConfig
	// will determine the redirection option
	logout() {
		return this.authService.logout();
	}

	authenticate(name) {
		return this.authService.authenticate(name)
			.then(response => {
				this.logger.debug("auth response " + response);
			});
	}

	@computedFrom('authService.authenticated')
	get authenticated() {
		return this.authService.authenticated;
	}
}