import { autoinject, inject, LogManager, bindable } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { DialogController, DialogService } from 'aurelia-dialog';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { ValidationController, ValidationControllerFactory, ControllerValidateResult } from 'aurelia-validation';
import { Rest, Endpoint } from 'aurelia-api';
import { SchoolClassViewModel } from '../../models/schoolclassview-model';
import { Helpers } from '../../services/helpers';
import { UserViewModel } from '../../models/userview-model';
import { UserEditViewModel } from '../../models/usereditview-model';
import { UserClaimsViewModel } from '../../models/userclaimsview-model';

@autoinject
export class StudentModalUpdate {
	@bindable id: string;
	model: UserEditViewModel;
	claimModel: UserClaimsViewModel;
	classes: SchoolClassViewModel[] = [];	
	logger: Logger = LogManager.getLogger('STUDENTMODALUPDATE');
	validationController: ValidationController;
	private saveDisabled: boolean = false;

	constructor(
		@inject(Endpoint.of('api'))
		private api: Rest,
		private dialog: DialogController,
		private validationControllerFactory: ValidationControllerFactory) {

		this.validationController = this.validationControllerFactory.createForCurrentScope();
		this.validationController.addRenderer(new BootstrapFormRenderer());
	}

	async activate(params: any) {
		this.logger.debug('activate');

		this.id = params.studentId;
		await this.getModel();
		await this.getSchoolClasses();

		await this.getUserClaims();
	}

	async getModel() {
		this.logger.debug('getModel');

		await this.api.findOne('/User/GetById', this.id)
			.then((data: UserViewModel) => {
				this.model = new UserEditViewModel(data);
				this.logger.debug('Success getModel: ', data);
			}).catch(async (err) => {
				this.logger.debug('Error getModel: ', err);
				await Helpers.ParseValidationError(err, this.validationController, this.model);
			});
	}

	async getUserClaims() {
		this.logger.debug('getUserClaims');

		await this.api.find('/User/GetOriginalUserClaimsById', this.id)
			.then((data: UserClaimsViewModel) => {
				this.claimModel = new UserClaimsViewModel(data);
				this.logger.debug('Success getUserClaims: ', data);
			}).catch(async (err) => {
				this.logger.debug('Error getUserClaims: ', err);
				await Helpers.ParseValidationError(err, this.validationController, this.model);
			});
	}

	async getSchoolClasses() {
		this.logger.debug('getSchoolClasses');

		await this.api.find('/SchoolClass/GetAll')
			.then((data: Array<SchoolClassViewModel>) => {
				this.classes = data;
				this.logger.debug('Success getSchoolClasses');
			}).catch(async (err) => {
				this.logger.debug('Error getSchoolClasses: ', err);
				await Helpers.ParseValidationError(err, this.validationController, this.model);
			});
	}

	async save() {
		this.logger.debug('save');

		this.saveDisabled = true;

		var selectedSchoolClass = this.classes.find(i => i.id == this.model.schoolClassId);
		if (selectedSchoolClass) {
			this.model.schoolYearId = selectedSchoolClass.schoolYearId;
			this.model.schoolId = selectedSchoolClass.schoolYear.schoolId;
		}

		const validation: ControllerValidateResult = await this.validationController.validate();

		if (validation.valid) {
			await this.api.update('/User/Update', this.id, this.model)
				.then((data: UserEditViewModel) => {
					this.logger.debug('Success save');
					this.saveDisabled = false;
					this.dialog.ok(data);
				}).catch(async (err) => {
					this.logger.debug('Error save: ', err);
					await Helpers.ParseValidationError(err, this.validationController, this.model);
					this.saveDisabled = false;
				});
		}
	}

	async cancel() {
		this.logger.debug('cancel');
		this.dialog.cancel();
	}

	async syncDivisions() {
		await this.api.update('/User/SyncDivisions', this.id)
			.then((data: any) => {
				this.logger.debug('Success syncing division');
				// refresh
				this.getModel();
			}).catch(async (err) => {
				this.logger.debug('Error save: ', err);
				await Helpers.ParseValidationError(err, this.validationController, this.model);
				this.saveDisabled = false;
			})
	}

	async convertToTeacher() {
		await this.api.update('/User/ConvertToTeacher', this.id)
			.then((data: any) => {
				this.logger.debug('Success converting student');
				// refresh
				this.dialog.ok();
			}).catch(async (err) => {
				this.logger.debug('Error converting: ', err);
				await Helpers.ParseValidationError(err, this.validationController, this.model);
				this.saveDisabled = false;
			})
	}
}