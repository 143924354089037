import { inject, autoinject, LogManager } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';
import { Endpoint, Rest } from 'aurelia-api';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { Helpers } from '../../services/helpers';
import { UserViewModel } from '../../models/userview-model';
import { Constants } from './../../models/constants';

@autoinject
export class UsersModalDelete {
	private logger: Logger = LogManager.getLogger('USERMODALDELETE');
	public model: UserViewModel;
	public count: number = 0;
	private validationController: ValidationController | null = null;
	private saveDisabled: boolean = false;

	constructor(@inject(Endpoint.of('api')) private api: Rest,
		public dialogController: DialogController,
		private validationControllerFactory: ValidationControllerFactory) {

		this.validationController = validationControllerFactory.createForCurrentScope();
		this.validationController.addRenderer(new BootstrapFormRenderer());
	}

	async activate(params: any) {
		this.logger.debug('activate');

		await this.api.find('/User/GetAll', Constants.roleAdministrator)
			.then(async (data: Array<UserViewModel>) => {
				this.count = data.length;
				this.logger.debug('Success: getModel');
			}).catch(async (err) => {
				var errorObj = await err.json();
				this.logger.debug('Error getModel: ', errorObj);
			});

		await this.api.findOne('/User/GetById', params.userId)
			.then((data: UserViewModel) => {
				this.model = data;
				this.logger.debug('Success activate' + this.count);
			}).catch(async (err) => {
				this.logger.debug('Error activate: ', await err.json());
				await Helpers.ParseValidationError(err, this.validationController, this.model);
			});
	}

	async delete(id) {
		this.logger.debug('delete');
		this.saveDisabled = true;

		const validation: ControllerValidateResult = await this.validationController.validate();

		if (validation.valid && this.count > 1) {
			this.api.destroy('/User/DeleteById', id)
				.then(async (data: any) => {
					this.saveDisabled = false;
					this.logger.debug('Success delete: ', data);
					await this.dialogController.ok(data);
				}).catch(async (err) => {
					this.saveDisabled = false;
					this.logger.debug('Error delete: ', err);
					await Helpers.ParseValidationError(err, this.validationController, this.model);
				});
		} else {
			this.saveDisabled = false;
		}

		this.saveDisabled = false;
	}

	async cancel() {
		await this.dialogController.cancel();
	}
}