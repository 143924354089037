import { Rest, Endpoint } from 'aurelia-api';
import { autoinject, inject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { DialogController } from 'aurelia-dialog';
import { Helpers } from '../../services/helpers';
import { SubAssignmentCreateViewModel } from './../../models/subassignmentcreateview-model'
import { AssignmentViewModel } from '../../models/assignmentview-model';

@autoinject
export class SubAssignmentsModalCreate {
    private validationController: ValidationController | null = null;
    private logger: Logger = LogManager.getLogger('SUBASSIGNMENTMODALCREATE');
    public model: SubAssignmentCreateViewModel = null;
    private saveDisabled: boolean = false;

	private assignments: AssignmentViewModel[] = [];
	public assignmentId: number | null = null;

    constructor(
        @inject(Endpoint.of('api'))
        private api: Rest,
        private validationControllerFactory: ValidationControllerFactory,
        private dialogController: DialogController) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.addRenderer(new BootstrapFormRenderer());
    }

    async activate(params: any) {
        this.logger.debug("activate");
        this.model = new SubAssignmentCreateViewModel();

		//optional
		if (params && params.assignmentId) {
			this.assignmentId = params.assignmentId;
		}

		await this.getOrderNumber();
        await this.getAssignments();
    }

    async getAssignments() {
        this.logger.debug('getAssignments');

        await this.api.find('/Assignment/GetAll_Sorted')
            .then((data: Array<AssignmentViewModel>) => {
				this.assignments = data;

				// set the assignmentId if it's provided
				if (this.assignmentId) {
					this.model.assignmentId = this.assignmentId;
				}

                this.logger.debug('Success getAssignments: ', data);
            }).catch(async (err) => {
                this.logger.debug('Error getAssignments: ', await err.json());
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

	async getOrderNumber() {
		this.logger.debug('getOrderNumber');

		if (this.assignmentId) {
			await this.api.find('/SubAssignment/GetMaxOrder', this.assignmentId)
				.then((data: number) => {
					this.model.order = data;
					this.logger.debug('Success getOrderNumber: ', data);
				}).catch(async (err) => {
					this.logger.debug('Error getOrderNumber: ', await err.json());
					await Helpers.ParseValidationError(err, this.validationController, this.model);
				});
		} else {
			this.model.order = 1;
		}
	}

    async save() {
		this.logger.debug('save');
        this.saveDisabled = true;

		const validation: ControllerValidateResult = await this.validationController.validate();

        if (validation.valid !== false) {
            await this.api.create('/SubAssignment/Insert', this.model)
                .then((data: SubAssignmentCreateViewModel) => {
					this.saveDisabled = false;
                    this.logger.debug('Success save: ', data);
					this.dialogController.ok(data);
                }).catch(async (err) => {
                    this.saveDisabled = false;
                    this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                });
		} else {
			this.saveDisabled = false;
		}
	}

	async cancel() {
		await this.dialogController.cancel();
	}
}