import { Endpoint, Rest } from 'aurelia-api';
import { DialogController } from 'aurelia-dialog';
import { autoinject, inject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { SchoolViewModel } from '../../models/schoolview-model';
import { SchoolYearCreateViewModel } from '../../models/schoolyearcreateview-model';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { Helpers } from '../../services/helpers';

@autoinject
export class SchoolYearsModalCreate {
    public model: SchoolYearCreateViewModel = null;
    private logger: Logger = LogManager.getLogger('SCHOOLYEARMODALCREATE');
    private validationController: ValidationController | null = null;
    private saveDisabled: boolean = false;
    private schools: SchoolViewModel[] = [];
    public schoolId: number | null = null;

    constructor(
        @inject(Endpoint.of('api')) private api: Rest,
        private dialog: DialogController,
        private validationControllerFactory: ValidationControllerFactory) {

        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.addRenderer(new BootstrapFormRenderer());
    }

    async activate(params: any) {
        this.logger.debug("activate");
        const currentYear = new Date().getFullYear();

        // optional
		if (params && params.schoolId) {
			this.schoolId = params.schoolId;
		}

        this.model = new SchoolYearCreateViewModel({
            startYear: currentYear,
            endYear: currentYear + 1
        });
        await this.getSchools();
    }

    async getSchools() {
        this.logger.debug('getSchools');

        await this.api.find('/School/GetAll')
            .then((data: Array<SchoolViewModel>) => {
                this.schools = data;

				// set the schoolId if it's provided
                if (this.schoolId) {
                    this.model.schoolId = this.schoolId;
                }

                this.logger.debug('Success getSchools: ', data);
            }).catch(async (err) => {
                this.logger.debug('Error getSchools: ', await err.json());
                await Helpers.ParseValidationError(err, this.validationController, this.model);
            });
    }

    async cancel() {
        this.logger.debug('cancel');
        await this.dialog.cancel();
    }

    async save() {
        this.logger.debug('save');
        this.saveDisabled = true;

        const validation: ControllerValidateResult = await this.validationController.validate();

        if (validation.valid) {
            await this.api.post('/SchoolYear/Insert', this.model)
                .then(async (data: any) => {
                    this.logger.debug('Success save: ', data);
                    this.saveDisabled = false;
                    await this.dialog.ok(data);
                }).catch(async (err) => {
                    this.logger.debug('Error save: ', err);
                    await Helpers.ParseValidationError(err, this.validationController, this.model);
                    this.saveDisabled = false;
                });
        } else {
            this.saveDisabled = false;
        }
    }
}