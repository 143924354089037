import { autoinject, computedFrom, Aurelia, PLATFORM } from "aurelia-framework";
import { Router, RouterConfiguration } from "aurelia-router";
import { AuthService } from "aurelia-authentication";
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import * as $ from 'jquery';
import { SharedState } from "./../../state/shared-state";
import { ValidateConfig } from "./../../config/validate-config";
import { NavConfig } from "./../../config/nav-config";
import { RoleStep } from "../../services/RoleStep";
import { SaveNavAuthenticateStep } from "../../services/SaveNavAuthenticateStep";

@autoinject
export class App {
	private router: Router;
	private toggle_sidebar: boolean = false;
	private toggle_topbar: boolean = false;
	private nav_open: boolean = false;
	private topbar_open: boolean = false;
	private rightMenuPinned: boolean = false;
	private subscriberFilterPinned: Subscription | null = null;
    private subscriberRouterNavComplete: Subscription | null = null;
	//private currentYear: string = DateTime.local().toFormat('yyyy');
	private route: string = '/';

	constructor(
		protected sharedState: SharedState,
		private auth: AuthService,
		private validateConfig: ValidateConfig,
		private ea: EventAggregator) {
		this.auth = auth;
	}

	async configureRouter(config: RouterConfiguration, router: Router) {
        // switch from hash (#) to slash (/) navigation
        //config.options.pushState = true;
		config.title = "Tree9 Controle Tool";

		// Here we hook into the authorize extensibility point
		// to add a route filter so that we can require authentication
		// on certain routes
		config.addAuthorizeStep(SaveNavAuthenticateStep);

		// also disallow based on given roles
		config.addAuthorizeStep(RoleStep);

		config.map(NavConfig);
		this.router = router;
	}

	async activate() {
		await this.validateConfig.configure();
	}

	public async attached() {
		//Settings.defaultLocale = "nl";
		//Settings.defaultZoneName = "Europe/Amsterdam";

        this.subscribe();
    }

    detached() {
        this.subscriberFilterPinned.dispose();
        this.subscriberRouterNavComplete.dispose();
	}

	@computedFrom('auth.authenticated')
	get isAuthenticated() {
		return this.auth.authenticated;
	}

    private async subscribe() {
		this.subscriberRouterNavComplete = this.ea.subscribe('router:navigation:complete', response => {
			this.route = response.instruction.fragment;
            this.sidenavToggle(true);
            this.topbarToggle(true);

            // check if the page has dom tag <sidebar-right>
            const hasSideBarTag = document.getElementById("content-container").getElementsByTagName("sidebar-right").length;

            if (hasSideBarTag > 0) {
                this.rightMenuPinned = this.sharedState.filtersSidebarPinned;
            } else {
                this.rightMenuPinned = false;
            }

        });

        this.subscriberFilterPinned = this.ea.subscribe('rightMenuPinnedChanged', (pinned: boolean) => {
            this.rightMenuPinned = pinned;
        });
    }

	private sideNavClickEvent = (event) => {
		if (!event.target.closest('#sidebar, .sidenav-toggler')) {
			if (this.nav_open === true) {
				this.sidenavToggle(null);
				this.sideNavOutsideClickEvent(false);
			}
		}
	};

	private topbarClickEvent = (event) => {
		if (!event.target.closest('#topbar, .topbar-toggler')) {
			if (this.topbar_open === true) {
				this.topbarToggle(null);
				this.topbarOutsideClickEvent(false);
			}
		}
	};

	async rightMenuPinnedChanged(oldVal, newVal) {
		console.info('app.ts - rightMenuPinnedChanged:', oldVal, newVal);
	}

	async sideNavOutsideClickEvent(enable: boolean) {
		const removeClickListener = () => {
			document.removeEventListener('click', this.sideNavClickEvent, true);
		}

		if (enable) {
			document.addEventListener('click', this.sideNavClickEvent, true);
		} else {
			removeClickListener();
		}
	}

	async sidenavToggle(close: boolean) {
		const $toggle = $('.sidenav-toggler');
		const $html = $('html');

		if (this.nav_open || close === true) {
			$('html').removeClass('nav_open');
			$toggle.removeClass('toggled');
			this.nav_open = false;
			this.sideNavOutsideClickEvent(false);
		} else {
			$('html').addClass('nav_open');
			$toggle.addClass('toggled');
			this.nav_open = true;
			this.sideNavOutsideClickEvent(true);
		}
	}

	async topbarOutsideClickEvent(enable: boolean) {
		const removeClickListener = () => {
			document.removeEventListener('click', this.topbarClickEvent, true);
		}

		if (enable) {
			document.addEventListener('click', this.topbarClickEvent, true);
		} else {
			removeClickListener();
		}
	}

	async topbarToggle(close: boolean) {
		const $topbar = $('.topbar-toggler');
		const $html = $('html');

		if (this.topbar_open || close === true) {
			$html.removeClass('topbar_open');
			$topbar.removeClass('toggled');
			this.topbar_open = false;
			this.topbarOutsideClickEvent(false);
		} else {
			$html.addClass('topbar_open');
			$topbar.addClass('toggled');
			this.topbar_open = true;
			this.topbarOutsideClickEvent(true);
		}
	}
}

