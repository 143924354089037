// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';


export class AnswerLogViewModel {
    date: Date = new Date(0);
	userId: string = null;
	schoolId: number = 0;
	schoolClassId: number = 0;
	schoolYearId: number = 0;
	courseId: number = 0;
	topicId: number = 0;
	assignmentId: number = 0;
	subAssignmentId: number = 0;
	answerId: number = 0;
	division: string = null;
	fetchedAnswer: string = null;
	triggeredBy: string = null;
	remark: string = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        
	}
}