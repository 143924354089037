// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';


export class FetchedDateEntityDivisionViewModel {
    division: string = null;
	entity: string = null;
	lastSyncDate: Date = new Date(0);
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('division').displayName('division').required()
			.ensure('entity').displayName('entity').required()
			.ensure('lastSyncDate').displayName('lastSyncDate').required()
			.on(this);
	}
}