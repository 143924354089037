// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';


export class UserCreateViewModel {
    email: string = null;
	fullName: string = null;
	phoneNumber: string = null;
	password: string = null;
	confirmPassword: string = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('email').displayName('E-mail').required()
			.ensure('fullName').displayName('Naam').required()
			.ensure('password').displayName('Wachtwoord').required()
			.ensure('confirmPassword').displayName('Herhaal wachtwoord').required().satisfiesRule('matchesProperty', 'Password')
			.on(this);
	}
}