import { valueConverter } from 'aurelia-framework';

/**
 * Sorts values by property and direction
 */

@valueConverter('sort')
export class SortValueConverter {
	toView(array: Array<any>, property: string, direction: string) {
		if (array) {
			const sorted = array.sort((a, b) => a[property] - b[property]);
			return direction === 'ascending' ? sorted : sorted.reverse();
		}
		return array;
	}
}
