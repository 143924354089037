// Auto generated by ControleTool.ModelClassGenerator
import { inject } from 'aurelia-framework';
import { ValidationRules } from 'aurelia-validation';
import { Helpers } from '../services/helpers';
import { SchoolViewModel } from './schoolview-model';


export class SchoolYearViewModel {
    id: number = 0;
	startYear: number = 0;
	endYear: number = 0;
	schoolId: number = 0;
	school: SchoolViewModel = null;
	
	constructor(data: any = null) {
        
		if (data) {
			Helpers.mapProperties(data, this);
        }
        ValidationRules
			.ensure('startYear').displayName('Startjaar').required()
			.ensure('endYear').displayName('Eindjaar').required()
			.ensure('schoolId').displayName('School').required()
			.on(this);
	}
}