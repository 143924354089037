import { inject, autoinject, LogManager } from 'aurelia-framework';
import { DialogController, DialogService } from 'aurelia-dialog';
import { Logger } from 'aurelia-logging';
import { Endpoint, Rest } from 'aurelia-api';
import { ControllerValidateResult, ValidationController, ValidationControllerFactory } from 'aurelia-validation';
import { BootstrapFormRenderer } from '../../services/bootstrap-form-renderer';
import { ApplicationUserEditViewModel } from '../../models/applicationusereditview-model';
import { UserEditViewModel } from '../../models/usereditview-model';
import { Helpers } from '../../services/helpers';
import { ProfileEOLModal } from './profile-eol-modal';
import { UserClaimsViewModel } from '../../models/userclaimsview-model';
import { DivisionViewModel } from '../../models/divisionview-model';
import { SharedState } from '../../state/shared-state';
import { AppSettingsViewModel } from '../../models/appsettingsview-model';

@autoinject
export class ProfileDialog_Update {
	private id: string;
	private logger: Logger = LogManager.getLogger('PROFILEMODALUPDATE');
	private validationController: ValidationController | null = null;
	private saveDisabled: boolean = false;
	private loginDisabled: boolean = false;
	private isRequesting: boolean = true;
	private autoFilled: boolean = false;
	private model: UserEditViewModel | null = null;
	private selectedClaims: UserClaimsViewModel | null = null;
	private divisions: DivisionViewModel[] | null = null;

	constructor(
		@inject(Endpoint.of('api'))
		private apiEndpoint: Rest,
		private sharedState: SharedState,
		private dlgController: DialogController,
		private validationControllerFactory: ValidationControllerFactory,
		private dialogService: DialogService) {

		this.validationController = validationControllerFactory.createForCurrentScope();
		this.validationController.addRenderer(new BootstrapFormRenderer());
	}

	async activate(params: any) {
		this.logger.debug("activate");
		this.isRequesting = true;
		this.id = params.userId;

		await this.getUser();
		await this.getClaims();

		this.isRequesting = false;
	}

	async getUser() {
		// we fetch the model again using the id, because if we don't the underlying screen also updates when we change something.
		await this.apiEndpoint.find('/User/GetById', this.id)
			.then(async (data: UserEditViewModel) => {
				this.logger.debug('Success activate: ', data);
				this.model = new UserEditViewModel(data);
			}).catch(async (err) => {
				this.logger.debug('Error activate: ', err);
				await Helpers.ParseValidationError(err, this.validationController, this.model);
			});
	}

	async getClaims() {
		// seperate function to get the claims
		await this.apiEndpoint.find('/User/GetUserClaimsById', this.id)
			.then(async (data: UserClaimsViewModel) => {
				this.logger.debug('Success getClaims: ', data);
				this.selectedClaims = new UserClaimsViewModel(data);

				// get associated divisions
				await this.GetDivisions();
			}).catch(async (err) => {
				this.logger.debug('Error getClaims: ', err);
				await Helpers.ParseValidationError(err, this.validationController, this.model);
			})
	}

	async GetDivisions() {
		// get divisions
		await this.apiEndpoint.find('/ExactOnline/GetDivisionsByUserId', this.id)
			.then(async (data: DivisionViewModel[]) => {
				this.logger.debug('Success getDivisions: ', data);
				this.divisions = data;
			}).catch(async (err) => {
				this.logger.debug('Error getDivisions: ', err);
				await Helpers.ParseValidationError(err, this.validationController, this.model);
			});
	}

	async cancel() {
		this.logger.debug('cancel');
		await this.dlgController.cancel();
	}

	async save() {
		this.logger.debug('save');
		this.saveDisabled = true;
		const validation: ControllerValidateResult = await this.validationController.validate();

		if (validation.valid) {
			await this.apiEndpoint.update('/User/Update', this.id, this.model)
				.then((data: UserEditViewModel) => {
					this.logger.debug('Success save');
					this.saveDisabled = false;

					// close modal
					this.dlgController.ok(data);
				}).catch(async (err) => {
					this.logger.debug('Error save: ', err);
					await Helpers.ParseValidationError(err, this.validationController, this.model);
					this.saveDisabled = false;
				});
		}
	}

	async connectEOL() {
		const validation: ControllerValidateResult = await this.validationController.validate();
		this.saveDisabled = true;

		if (validation.valid) {
			this.logger.debug('connectEOL');

			let appConfig: AppSettingsViewModel = await this.sharedState.getAppSettings();
			var uri = appConfig.portalPath + "/Identity/Account/LogIn?passedGuid=" + this.id;

			window.open(uri);

			await this.save();
		} else {
			this.validationController.addError("Kan geen account koppelen als overige gegevens niet correct zijn.", null);
		}

		this.saveDisabled = false;
	}

	async refreshAdminEOL() {
		const validation: ControllerValidateResult = await this.validationController.validate();
		this.saveDisabled = true;

		if (validation.valid) {
			this.logger.debug('refreshEOLDataAdmin');

			await this.apiEndpoint.find('/ExactOnline/RefreshEOLDataAdmin', this.id)
				.then(async (data) => {
					this.logger.debug('Success getDivisions: ', data);
				}).catch(async (err) => {
					this.logger.debug('Error getDivisions: ', err);
					await Helpers.ParseValidationError(err, this.validationController, this.model);
				});
		}
		this.saveDisabled = false;
	}
}
